import React, { useEffect, useRef } from 'react';

import Tippy from '@tippyjs/react';
import GlobalPersonDrawer from 'app/components/Drawers/GlobalPerson/GlobalPersonDrawer';

import SideDrawer from '../SideDrawer';
import {
  PersonAvatarContainer,
  PersonAvatarImage,
  PersonAvatarText,
  PersonAvatarTippyContainer,
  PersonAvatarTippyName,
  PersonAvatarTippyRole,
} from './styled';

interface IProps {
  avatar?: string;
  highlighted?: boolean;
  lastName?: string;
  margin?: string;
  name?: string;
  onClick?: () => void;
  size?: number;
  text?: string;
  role?: string;
  hideTooltip?: boolean;
  slug?: string;
  openDrawer?: boolean;
  disabled?: boolean;
  extraStyle?: React.CSSProperties;
  textColor?: string;
  noBorders?: boolean;
  children?: React.ReactNode;
  disablePropagation?: boolean;
  isAlumni?: boolean;
  drawerBehavioralAction?: () => void;
}

const PersonAvatar = React.forwardRef<HTMLDivElement, IProps>(
  (
    {
      avatar,
      highlighted = false,
      lastName,
      margin = '0',
      name,
      onClick,
      size = 20,
      text, //in case PersonAvatar needs to be used to display short text
      role,
      hideTooltip = true,
      slug,
      openDrawer = false,
      disabled = false,
      extraStyle,
      textColor,
      noBorders = false,
      children,
      disablePropagation = false,
      isAlumni = false,
      drawerBehavioralAction,
    },
    ref
  ) => {
    const tippyInstance = useRef<any>(null);
    const [showPersonDrawer, setShowPersonDrawer] = React.useState<boolean>(false);

    const handleScroll = () => {
      if (tippyInstance.current) {
        tippyInstance.current.hide();
        tippyInstance.current = null;
      }
    };

    useEffect(() => {
      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    return (
      <>
        <Tippy
          popperOptions={{
            placement: 'bottom',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [8, 8],
                },
              },
            ],
          }}
          placement="bottom-start"
          animation={false}
          trigger="mouseenter"
          onShow={(instance) => {
            tippyInstance.current = instance;
          }}
          onHide={(instance) => {
            tippyInstance.current = null;
          }}
          render={(attrs) => (
            <PersonAvatarTippyContainer hide={hideTooltip} tabIndex={-1} {...attrs}>
              <PersonAvatarTippyName>
                {name} {lastName}
              </PersonAvatarTippyName>
              {role ? <PersonAvatarTippyRole>{role}</PersonAvatarTippyRole> : null}
            </PersonAvatarTippyContainer>
          )}
        >
          <PersonAvatarContainer
            className={slug ? `${slug}-avatar` : 'person-avatar'}
            textColor={textColor}
            style={extraStyle}
            disabled={disabled}
            clickable={!!onClick || openDrawer}
            highlighted={highlighted}
            margin={margin}
            noBorders={noBorders}
            onClick={(e) => {
              if (!disablePropagation) {
                e.stopPropagation();
              }
              if (onClick) {
                onClick();
              } else if (openDrawer) {
                drawerBehavioralAction?.();
                setShowPersonDrawer(true);
              }
            }}
            size={size}
            withAvatar={avatar !== null}
            isAlumni={isAlumni}
          >
            {text ? (
              <PersonAvatarText size={size}>{text}</PersonAvatarText>
            ) : !name ? (
              <PersonAvatarImage
                alt="loading-avatar"
                size={size}
                src="/icons/avatar-placeholder.svg"
                loading="lazy"
              />
            ) : avatar ? (
              <PersonAvatarImage
                src={avatar}
                size={size}
                alt={name + lastName}
                className="avatar-image"
                loading="lazy"
              />
            ) : (
              <PersonAvatarText size={size}>
                {!lastName ? `${name[0] + name[1]}` : `${name[0] + lastName[0]}`}
              </PersonAvatarText>
            )}
            {children}
          </PersonAvatarContainer>
        </Tippy>
        {showPersonDrawer && (
          <SideDrawer closeDrawer={() => setShowPersonDrawer(false)} withClose>
            <GlobalPersonDrawer slug={slug} />
          </SideDrawer>
        )}
      </>
    );
  }
);

export default PersonAvatar;
