import styled from 'styled-components';
import {
  appearAnimation,
  disappearAnimation,
  slideFromRightAnimation,
  slideToRightAnimation,
} from 'styles/animations';
import { BREAKPOINTS } from 'styles/media';

type DrawerContentWrapperProps = {
  triggerClosing?: boolean;
};

export const DrawerOverlay = styled.div<DrawerContentWrapperProps>`
  background: rgba(15, 15, 15, 0.15);
  height: 100svh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999998;
  transition: opacity 0.5s ease;
  ${appearAnimation('1')}
  ${({ triggerClosing }) => (triggerClosing ? disappearAnimation('1') : ``)}
`;

export const DrawerContentWrapper = styled.aside<DrawerContentWrapperProps>`
  ${slideFromRightAnimation()};
  min-height: 100svh;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  width: 520px;
  z-index: 99999999;
  > button.close-button {
    position: absolute;
    right: 24px;
    top: 20px;
    z-index: 999999991;
  }
  ${({ triggerClosing }) => (triggerClosing ? slideToRightAnimation() : ``)}
  ${BREAKPOINTS.tablet} {
    width: 100%;
    z-index: 9999999999;
    > button.close-button {
      > svg {
        height: 18px;
        width: 18px;
      }
    }
  }
`;
