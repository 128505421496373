import React, { Suspense, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';

import { CloseIcon } from 'app/components/shared/icons';
import useOnClickOutside from 'hooks/click-outside';
import { setIsDrawerOpen } from 'store/reducers/navbar-reducer';

import { DrawerContentWrapper, DrawerOverlay } from './styled';

interface IProps {
  children: JSX.Element;
  closeDrawer: () => void | null;
  withClose?: boolean;
  confirmBeforeClosing?: string;
}

export default function SideDrawer({ children, closeDrawer, withClose, confirmBeforeClosing = null }: IProps) {
  const drawerContentRef = useRef(null);
  const [triggerClosing, setTriggerClosing] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => {
    if (confirmBeforeClosing !== null && confirm(confirmBeforeClosing)) {
      setTriggerClosing(true)
    } else if (confirmBeforeClosing === null) {
      setTriggerClosing(true);
    }
  }

  useOnClickOutside(drawerContentRef, handleClose);

  useEffect(() => {
    dispatch(setIsDrawerOpen(true));
    window.addEventListener('message', (event) => {
      if (event.data === 'drawers:close') {
        setTriggerClosing(true);
      }
    });

    return () => {
      window.removeEventListener('message', (event) => {
        if (event.data === 'drawers:close') {
          setTriggerClosing(true);
        }
      });
    };
  }, []);

  useEffect(() => {
    if (triggerClosing) {
      setTimeout(() => {
        if (closeDrawer !== null) {
          dispatch(setIsDrawerOpen(false));
          closeDrawer();
        }
      }, 500);
    }
  }, [triggerClosing]);

  return ReactDOM.createPortal(
    <>
      <DrawerOverlay triggerClosing={triggerClosing} />

      <DrawerContentWrapper
        ref={drawerContentRef}
        triggerClosing={triggerClosing}
        data-type="sidedrawer"
      >
        {withClose ? (
          <button
            className="close-button"
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon />
          </button>
        ) : null}
        <Suspense
          fallback={<div style={{ minHeight: '100svh', width: '100%', background: 'white' }}></div>}
        >
          {children}
        </Suspense>
      </DrawerContentWrapper>
    </>,
    document.getElementById('root')
  );
}
