import React from 'react';

export default function CheckIcon({
  width = 16,
  height = 17,
  background = '#8DA76C',
  fill = '#FFFFFF',
  style = {},
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M0 8.5C0 4.08172 3.58172 0.5 8 0.5C12.4183 0.5 16 4.08172 16 8.5C16 12.9183 12.4183 16.5 8 16.5C3.58172 16.5 0 12.9183 0 8.5Z"
        fill={background}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3737 5.87361L6.68681 11.5605L3.33325 8.20694L4.04036 7.49984L6.68681 10.1463L11.6666 5.1665L12.3737 5.87361Z"
        fill={fill}
      />
    </svg>
  );
}
