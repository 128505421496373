import { QueryKey, UseQueryOptions, useQuery } from 'react-query';

import { getFilters } from 'services/people';
import { IPeopleFilter } from 'store/reducers/people/people-reducer';

export default function usePeopleFilters<T>(
  type: string = null,
  options?: Omit<
    UseQueryOptions<IPeopleFilter[] | T, Error, IPeopleFilter[] | T, QueryKey>,
    'queryKey'
  >
) {
  return useQuery<IPeopleFilter[] | T, Error>(
    [`people-filters`, type],
    () =>
      getFilters(type).then((response) => {
        if (!response.ok) {
          throw new Error(response.originalError.message);
        }
        return response.data;
      }),
    {
      ...options,
      cacheTime: 600000,
    }
  );
}
