import React, { useEffect } from 'react';

import { ToggleButtonTitle, ToggleLabel, ToggleButtonContainer } from './styled';

interface TogglebuttonProps {
  label: string;
  onChange: (checked?: boolean) => void;
  disabled?: boolean;
  checked?: boolean;
  labelColor?: string;
  toggleColor?: string;
  border?: string;
  preventStateChangeOnClick?: boolean;
}

export default function ToggleButton({
  label,
  onChange,
  checked = false,
  disabled = false,
  labelColor = '#FFFFFF',
  toggleColor = '#ffc229',
  border = '1px solid var(--color-lightgray)',
  preventStateChangeOnClick = false,
}: TogglebuttonProps) {
  const [checkedState, setCheckedState] = React.useState(checked);

  useEffect(() => {
    setCheckedState(checked);
  }, [checked]);

  return (
    <ToggleLabel>
      <ToggleButtonTitle color={labelColor} disabled={disabled}>
        {label}
      </ToggleButtonTitle>
      <ToggleButtonContainer disabled={disabled} toggleColor={toggleColor} border={border}>
        <input
          type="checkbox"
          onChange={() => {
            onChange(!checkedState);
            if (!preventStateChangeOnClick) {
              setCheckedState(!checkedState);
            }
          }}
          checked={checkedState}
        />
        <span className="slider round"></span>
      </ToggleButtonContainer>
    </ToggleLabel>
  );
}
