import React, { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, useLocation } from 'react-router-dom';

import { LoadingComponent } from 'app/components';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import { isDrawerOpenSelector } from 'store/reducers/navbar-reducer';

const Footer = React.lazy(() => import('app/components/CommonStyled/Footer'));
const ContactDrawers = React.lazy(() => import('app/components/ContactDrawers'));
const GlobalHomeDrawers = React.lazy(() => import('app/components/CommonStyled/GlobalHomeDrawers'));
const LoginPage = React.lazy(() => import('app/pages/Login'));

interface IProps {
  children: JSX.Element;
  exact?: boolean;
  path: string;
}

export default function PrivateRoute({ children, exact, path }: IProps) {
  const location = useLocation();
  const { isLoggedIn } = useIsLoggedIn();
  const hideOnRoutes = ['/embed', '/map', '/resource', '/financial-report', '/the-well-report'];
  const isCompanyResource = new RegExp('resource/*/embed').test(location.pathname);
  const isMap = new RegExp('abstract_map|map').test(location.pathname);
  const isDrawerOpen = useSelector(isDrawerOpenSelector);

  const hide =
    !!hideOnRoutes.find((p) => location.pathname.endsWith(p)) || isCompanyResource || isMap;

  useEffect(() => {
    if (isDrawerOpen) {
      window.postMessage('drawers:close', '*');
    }
  }, [location]);

  useEffect(() => {
    const detailsPagesRoutes = [
      'client',
      'member-company',
      'discipline',
      'industry',
      'passion',
      'person',
      'project',
      'skill',
      'kyu',
    ];
    const route = location.pathname.split('/')[1];
    if (!detailsPagesRoutes.includes(route)) {
      document.querySelector('#root').setAttribute('style', 'background: unset;');
    } else {
      document.querySelector('#root').removeAttribute('style');
    }
  }, [location]);

  return (
    <Suspense fallback={<LoadingComponent idParticles="graph-page-particles" messages={[]} />}>
      {isLoggedIn ? (
        <Route
          exact={exact}
          path={path}
          render={() => (
            <>
              {children}
              {!hide ? <Footer /> : null}
              <ContactDrawers />
              <GlobalHomeDrawers />
            </>
          )}
        />
      ) : isLoggedIn === false ? (
        <LoginPage />
      ) : null}
    </Suspense>
  );
}
