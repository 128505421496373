export type TotalType = 'minimal' | 'with_icon' | 'with_side_icon';

export interface TotalProps {
  type: TotalType;
  dataType: TotalDataType;
  title?: string;
  counter: number;
  onClick?: () => void;
  theme?: 'light' | 'dark';
  tooltip?: string;
}

export enum TotalDataType {
  PEOPLE = 'people',
  SKILLS = 'kyu_skills',
  PASSIONS = 'passions',
  PROJECTS = 'projects',
  COMPANIES = 'companies',
  INDUSTRIES = 'industries',
  COMMUNITIES = 'communities',
  CONVENINGS = 'convenings',
  LANGUAGES = 'languages',
  COUNTRIES = 'countries',
  CITIES = 'cities',
  PRIOR_CLIENTS = 'prior_clients',
  PRIOR_COMPANIES = 'prior_companies',
  PROJECT_CLIENTS = 'project_clients',
  PROJECT_COMPANIES = 'project_companies',
}

export interface TotalTitleObject {
  singular: string;
  plural: string;
}

export interface ITotalThemeMap {
  light: string;
  dark: string;
}
