import { ApiResponse } from 'apisauce';
import { IPeopleFilter, IPeopleOnboarding } from 'store/reducers/people/people-reducer';

import { http } from './http';

export const getPeople = (filters = null, per_page) => {
  return http.post('/collective_index', { ...filters, samples: per_page });
};

export const getSamples = (uuids: string[]): Promise<ApiResponse<unknown, unknown>> => {
  if (uuids.length === 0) throw new Error('uuids is empty');
  return http.post('/collective_index/samples', { uuids });
};

export const getFilters = (type = null) => {
  return http.get<IPeopleFilter[]>('/collective_index/filters', { type });
};

export const getSearch = (term: string) => {
  return http.get('/collective_index/search', { term });
};

export const postContactEmail = (data: { subject: string; message: string; uuids: string[] }) => {
  return http.post('/mailer/contact', data);
};

export const getPeopleOnboarding = () => {
  return http.get<IPeopleOnboarding>('/collective_index/onboarding');
};
