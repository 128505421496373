import React, { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import SideDrawer from 'app/components/CommonStyled/SideDrawer';
import FeedbackDrawer from 'app/components/ContactDrawers/FeedbackDrawer';
import SubmitContentDrawer from 'app/components/ContactDrawers/SubmitContentDrawer';
import {
  BookIcon,
  FeedbackIcon,
  InfoIcon,
  PersonIcon,
  PlayIcon,
  QuestionIcon,
  ResourceIcon,
} from 'app/components/shared/icons';
import Calendar from 'app/components/shared/icons/calendar';
import useOnClickOutside from 'hooks/click-outside';
import useBehavioral from 'hooks/useBehavioral';
import { ThemeContext } from 'styled-components';

import {
  NavSectionsOpenWaysToGetInvolvedButton,
  NavSectionsOpenWaysToGetInvolvedButtonLabel,
} from '../styled';
import { handleSignOut } from '../utils';
import MobileMenuProfile from './MobileMenuProfile';
import MobileMenuTiles from './MobileMenuTiles';
import {
  MobileMenuProfileContainer,
  MobileMenuProfileItemContainer,
  MobileMenuProfileItemText,
  MobileMenuProfileSignOutButton,
  MobileMenuProfileWrapper,
  MobileMenuWaysToGetInvolvedContainer,
  MobileMenuWaysToGetInvolvedTitle,
  MobileMenuWaysToGetInvolvedWrapper,
  MobileMenuWrapper,
} from './sytled';

interface MobileMenuProps {
  setIsMobileMenuOpen: (isOpen: boolean) => void;
}

export default function MobileMenu({ setIsMobileMenuOpen }: MobileMenuProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showSubmitContentDrawer, setShowSubmitContentDrawer] = useState<boolean>(false);
  const [showReportAnIssue, setShowReportAnIssue] = useState<boolean>(false);
  const menuWrapperRef = useRef<HTMLDivElement>(null);
  const { push } = useHistory();
  const theme = useContext(ThemeContext);

  const hostAnEventBehavioral = useBehavioral('NAV_HOST_AN_EVENT');
  const pitchAStoryBehavioral = useBehavioral('NAV_PITCH_A_STORY');
  const submitAResourceBehavioral = useBehavioral('NAV_SUBMIT_A_RESOURCE');

  useOnClickOutside(menuWrapperRef, (event) => {
    const mobileSearchMenu = document.getElementById('mobile-search-menu');
    const menuButton = document.getElementById('menu-button');
    const drawer = document.querySelector('[data-type="sidedrawer"]');

    if (mobileSearchMenu && mobileSearchMenu.contains(event.target as Node)) return;
    if (menuButton && menuButton.contains(event.target as Node)) return;
    if (drawer && drawer.contains(event.target as Node)) return;

    setIsMobileMenuOpen(false);
  });

  const handleOnboardingClick = () => {
    push({ pathname: `/resources/kyu-storybook` });
    setIsMobileMenuOpen(false);
  };

  const handleFAQClick = () => {
    push({ pathname: '/about-kyu-os', hash: '#faq' });
    setIsMobileMenuOpen(false);
  };

  return (
    <MobileMenuWrapper ref={menuWrapperRef}>
      <MobileMenuProfile isOpen={isOpen} setIsOpen={setIsOpen} />
      {!isOpen ? (
        <>
          <MobileMenuTiles setIsOpen={setIsMobileMenuOpen} />
          <MobileMenuWaysToGetInvolvedWrapper>
            <MobileMenuWaysToGetInvolvedTitle>GET INVOLVED</MobileMenuWaysToGetInvolvedTitle>
            <MobileMenuWaysToGetInvolvedContainer>
              <NavSectionsOpenWaysToGetInvolvedButton
                onClick={(e) => {
                  e.preventDefault();
                  hostAnEventBehavioral();
                  window.open('https://forms.gle/9BqVtHRrRBCxwis38', '_blank');
                }}
              >
                <Calendar width={24} height={24} fill="var(--color-primary)" />
                <NavSectionsOpenWaysToGetInvolvedButtonLabel>
                  HOST AN EVENT
                </NavSectionsOpenWaysToGetInvolvedButtonLabel>
              </NavSectionsOpenWaysToGetInvolvedButton>
              <NavSectionsOpenWaysToGetInvolvedButton
                onClick={() => {
                  pitchAStoryBehavioral();
                  setShowSubmitContentDrawer(true);
                }}
                stroke
              >
                <BookIcon width={24} height={24} fill="var(--color-primary)" />
                <NavSectionsOpenWaysToGetInvolvedButtonLabel>
                  PITCH A STORY
                </NavSectionsOpenWaysToGetInvolvedButtonLabel>
              </NavSectionsOpenWaysToGetInvolvedButton>
              <NavSectionsOpenWaysToGetInvolvedButton
                onClick={() => {
                  submitAResourceBehavioral();
                  setShowSubmitContentDrawer(true);
                }}
              >
                <ResourceIcon width={24} height={24} fill="var(--color-primary)" />
                <NavSectionsOpenWaysToGetInvolvedButtonLabel>
                  SUBMIT A RESOURCE
                </NavSectionsOpenWaysToGetInvolvedButtonLabel>
              </NavSectionsOpenWaysToGetInvolvedButton>
            </MobileMenuWaysToGetInvolvedContainer>
          </MobileMenuWaysToGetInvolvedWrapper>
          {showSubmitContentDrawer ? (
            <SideDrawer closeDrawer={() => setShowSubmitContentDrawer(false)} withClose>
              <SubmitContentDrawer />
            </SideDrawer>
          ) : null}
        </>
      ) : (
        <MobileMenuProfileWrapper>
          <MobileMenuProfileContainer>
            <MobileMenuProfileItemContainer onClick={() => push('/my-profile')}>
              <PersonIcon width={22} height={22} fill={theme.color} />
              <MobileMenuProfileItemText>Profile</MobileMenuProfileItemText>
            </MobileMenuProfileItemContainer>
            <MobileMenuProfileItemContainer
              onClick={() => window.open('https://kyu.com/legal/employee-privacy-notice', '_blank')}
            >
              <InfoIcon width={22} height={22} fill={theme.color} />
              <MobileMenuProfileItemText>Privacy Policy</MobileMenuProfileItemText>
            </MobileMenuProfileItemContainer>
            <MobileMenuProfileItemContainer onClick={() => setShowReportAnIssue(true)}>
              <FeedbackIcon width={22} height={22} fill={theme.color} />
              <MobileMenuProfileItemText>Feedback</MobileMenuProfileItemText>
            </MobileMenuProfileItemContainer>
            <MobileMenuProfileItemContainer onClick={handleFAQClick}>
              <QuestionIcon width={22} height={22} fill={theme.color} />
              <MobileMenuProfileItemText>FAQ</MobileMenuProfileItemText>
            </MobileMenuProfileItemContainer>
            <MobileMenuProfileItemContainer onClick={handleOnboardingClick}>
              <PlayIcon width={22} height={22} fill={theme.color} />
              <MobileMenuProfileItemText>KYU 101</MobileMenuProfileItemText>
            </MobileMenuProfileItemContainer>
          </MobileMenuProfileContainer>
          <MobileMenuProfileSignOutButton onClick={handleSignOut}>
            Sign Out
          </MobileMenuProfileSignOutButton>
        </MobileMenuProfileWrapper>
      )}
      {showReportAnIssue ? (
        <SideDrawer closeDrawer={() => setShowReportAnIssue(false)} withClose>
          <FeedbackDrawer />
        </SideDrawer>
      ) : null}
    </MobileMenuWrapper>
  );
}
