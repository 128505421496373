import React, { useState } from 'react';

import { sendContentRequest } from 'services/app';

import {
  FeedbackSentSection,
  SubmitContentBottomContainer,
  SubmitContentButton,
  SubmitContentButtonTitle,
  SubmitContentCenterContainer,
  SubmitContentInput,
  SubmitContentInputContainer,
  SubmitContentInputLabel,
  SubmitContentLitleTitle,
  SubmitContentSelect,
  SubmitContentTitle,
  SubmitContentTopTextContainer,
  SubmitContentWrapper,
} from './styled';

export default function SubmitContentDrawer() {
  const [feedbackError, setFeedbackError] = useState<boolean>(false);
  const [feedbackSending, setFeedbackSending] = useState<boolean>(false);
  const [feedbackSent, setFeedbackSent] = useState<boolean>(false);

  const [resourceRequest, setResourceRequest] = useState<any>({
    resource_type: 'a tool',
    description: '',
    external_link: '',
  });

  const handleSubmit = async () => {
    if (!resourceRequest.description) return;

    setFeedbackSending(true);

    const sendResourceRequest = await sendContentRequest(resourceRequest);

    setFeedbackSending(false);

    if (sendResourceRequest.ok) return setFeedbackSent(true);

    return setFeedbackError(true);
  };

  return (
    <SubmitContentWrapper>
      {!feedbackSent ? (
        <>
          <div>
            <SubmitContentTopTextContainer>
              <SubmitContentLitleTitle>SHARE YOUR STORY</SubmitContentLitleTitle>
              <SubmitContentTitle>Let us know if you have something to share!</SubmitContentTitle>
            </SubmitContentTopTextContainer>
            <SubmitContentCenterContainer>
              <SubmitContentInputContainer>
                <SubmitContentInputLabel for="tool-type">
                  What type of content do you have in mind?
                </SubmitContentInputLabel>
                <SubmitContentSelect
                  name="types"
                  id="tool-type"
                  onChange={(event) => {
                    setResourceRequest({ ...resourceRequest, resource_type: event.target.value });
                  }}
                >
                  <option value="a tool">Tool: Something to use around the collective</option>
                  <option value="a story/news/case">
                    Story/News/Case Study: Something you’ve been working on you’d like to share out
                  </option>
                  <option value="an event">Event: Something coming up open to all</option>
                </SubmitContentSelect>
              </SubmitContentInputContainer>
              <SubmitContentInputContainer>
                <SubmitContentInputLabel for="tool-description">
                  Please tell us more:
                </SubmitContentInputLabel>
                <SubmitContentInput
                  id={'tool-description'}
                  onChange={(event) => {
                    setResourceRequest({ ...resourceRequest, description: event.target.value });
                  }}
                  value={resourceRequest.description}
                  placeholder="Add description..."
                  type="text"
                />
              </SubmitContentInputContainer>
              <SubmitContentInputContainer>
                <SubmitContentInputLabel for="tool-external-link">
                  If you have an external link you want to include, add it below (optional):
                </SubmitContentInputLabel>
                <SubmitContentInput
                  id={'tool-external-link'}
                  onChange={(event) => {
                    setResourceRequest({ ...resourceRequest, external_link: event.target.value });
                  }}
                  value={resourceRequest.external_link}
                  placeholder="Add link..."
                  type="text"
                />
              </SubmitContentInputContainer>
            </SubmitContentCenterContainer>
          </div>
          <SubmitContentBottomContainer>
            <SubmitContentButtonTitle>
              Thanks for letting us know. We’ll get back to you within the next week.
            </SubmitContentButtonTitle>
            <SubmitContentButton
              disabled={!resourceRequest.description || feedbackSending}
              onClick={handleSubmit}
            >
              Send
            </SubmitContentButton>
          </SubmitContentBottomContainer>
        </>
      ) : null}
      {feedbackSent && (
        <FeedbackSentSection>
          <svg width="305" height="35" viewBox="0 0 305 35" fill="none">
            <path
              d="M8.02497 29.4994L288.025 15.4994L287.975 14.5006L7.97503 28.5006L8.02497 29.4994Z"
              fill="#9CCAFF"
            />
            <circle cx="6.5" cy="28.5" r="6.5" fill="#171C33" />
            <circle opacity="0.4" cx="290" cy="15" r="15" fill="#FFD25F" />
            <circle cx="290" cy="15" r="9" fill="#FFD25F" />
          </svg>
          <h3>Thank you</h3>
          <p>
            Your feedback has been sent directly to us at kyu! Your contribution is truly
            appreciated.
          </p>
        </FeedbackSentSection>
      )}
    </SubmitContentWrapper>
  );
}
