import { configureStore } from '@reduxjs/toolkit';

import authReducer from './reducers/auth-reducer';
import bannerReducer from './reducers/banner-reducer';
import behavioralReducer from './reducers/behavioral-reducer';
import detailPageReducer from './reducers/detail-page/detail-page-reducer';
import globalReducer from './reducers/global/global-reducer';
import highlightReducer from './reducers/highlight-reducer';
import historyReducer from './reducers/history/history-reducer';
import kinReducer from './reducers/kin-reducer';
import mapReducer from './reducers/map/map-reducers';
import navbarReducer from './reducers/navbar-reducer';
import onboardingReducer from './reducers/onboarding-reducer';
import peopleReducer from './reducers/people/people-reducer';
import profileEditorReducer from './reducers/profile-editor/profile-editor-reducer';
import resultsReducer from './reducers/results-reducer';
import toastReducer from './reducers/toast-reducer';
import userReducer from './reducers/user-reducer';

export default configureStore({
  reducer: {
    kin: kinReducer,
    map: mapReducer,
    navbar: navbarReducer,
    onboarding: onboardingReducer,
    user: userReducer,
    results: resultsReducer,
    auth: authReducer,
    banner: bannerReducer,
    behavioral: behavioralReducer,
    highlight: highlightReducer,
    profileEditor: profileEditorReducer,
    toast: toastReducer,
    people: peopleReducer,
    history: historyReducer,
    detailPage: detailPageReducer,
    global: globalReducer,
  },
});
