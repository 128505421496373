import React, { useEffect, useRef } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import ProfileEditInput from 'app/components/CommonStyled/ProfileEditInput';
import { ProjectIcon } from 'app/components/DetailsPages/shared/CommonGround/Icons';
import { Form, Formik, FormikProps } from 'formik';
import useUserData from 'hooks/useUserData';
import { postRoleDescription } from 'services/profile-editor';
import { setRoleDescription } from 'store/reducers/profile-editor/profile-editor-reducer';
import { setUserData } from 'store/reducers/user-reducer';
import * as Yup from 'yup';

import useRoleDescriptionDrawer from '../../hooks/useRoleDescriptionDrawer';
import ProfileEditorDrawer, { ProfileEditorDrawerRefProps } from '../Drawer';
import { ProfileEditorDrawerTitle } from '../styled';
import {
  ProfileEditorDrawerMyRoleTitleContainer,
  ProfileEditorDrawerMyRoleWrapper,
} from './styled';

interface ProfileEditorDrawerMyRoleProps {
  onClose: () => void;
}

export default function ProfileEditorDrawerMyRole({ onClose }: ProfileEditorDrawerMyRoleProps) {
  const dispatch = useDispatch();
  const userData = useUserData();
  const formikRef = useRef<FormikProps<any>>(null);
  const drawerRef = useRef<ProfileEditorDrawerRefProps>(null);
  const { role_description } = useRoleDescriptionDrawer();

  useEffect(() => {
    if (role_description) {
      formikRef.current.setFieldValue('role_description', role_description);
    }
  }, [role_description]);

  const handlePostRoleDescription = async (params: any) => {
    const response = await postRoleDescription(params);
    if (!response.ok) {
      throw new Error(response.originalError?.message);
    }

    dispatch(setRoleDescription(params.value));
    dispatch(setUserData({ ...userData, role_name: params.value }));

    return response.data;
  };

  const { mutate } = useMutation(handlePostRoleDescription, drawerRef.current?.handleMutation);

  const roleDescriptionValidationSchema = Yup.object().shape({
    role_description: Yup.string().max(
      500,
      ({ value }) =>
        `${value.length} characters over the limit. Update your role description to fit within the limit to publish it to your profile!`
    ),
  });

  const handleSubmit = (values) => {
    mutate({ value: values.role_description });
  };

  return (
    <Formik
      initialValues={{
        role_description,
      }}
      validationSchema={roleDescriptionValidationSchema}
      onSubmit={handleSubmit}
      innerRef={formikRef}
    >
      {(props) => (
        <Form>
          <ProfileEditorDrawer
            ref={drawerRef}
            header={<ProfileEditorDrawerMyRoleHeader />}
            needsSaving={props.dirty}
            onClose={onClose}
          >
            <ProfileEditorDrawerMyRoleWrapper>
              <ProfileEditInput
                {...props}
                name={'role_description'}
                label={`You're in a Zoom call with lots of new faces and you're asked to share your role - how would that go?`}
                type={'text'}
                tooltip={
                  'We encourage you to share more details about your role that people can’t get from your job title.'
                }
                placeholder={
                  'I mostly work with internal stakeholders on projects that focus on improving workplace experience...'
                }
                textLimit={500}
              />
            </ProfileEditorDrawerMyRoleWrapper>
          </ProfileEditorDrawer>
        </Form>
      )}
    </Formik>
  );
}

function ProfileEditorDrawerMyRoleHeader() {
  const userData = useUserData();

  return (
    <ProfileEditorDrawerMyRoleTitleContainer>
      <ProjectIcon width={24} height={24} fill={'var(--color-data-project-foreground)'} />
      <ProfileEditorDrawerTitle>My Role at {userData.kyu_company?.name}</ProfileEditorDrawerTitle>
    </ProfileEditorDrawerMyRoleTitleContainer>
  );
}
