import React from 'react';

import Tippy from '@tippyjs/react';
import { InfoIcon } from 'app/components/shared/icons';
import { Field, FormikProps } from 'formik';
import useTippy from 'hooks/useTippy';
import { followCursor } from 'tippy.js';

import ProfileEditInputSelect from './ProfileEditInputSelect/ProfileEditInputSelect';
import ProfileEditInputText from './ProfileEditInputText/ProfileEditInputText';
import {
  ProfileEditInputLabel,
  ProfileEditInputLabelContainer,
  ProfileEditInputLabelTooltipContainer,
  ProfileEditInputLabelTooltipIconContainer,
  ProfileEditInputLabelTooltipText,
  ProfileEditInputWrapper,
} from './styled';

export interface ProfileEditInputProps extends FormikProps<any> {
  type: 'text' | 'select' | 'custom';
  label?: string;
  textLimit?: number;
  tooltip?: string;
  placeholder?: string;
  name: string;
  options?: {
    value: any;
    label: string;
  }[];
  settings?: {
    allowMultiple?: boolean;
    allowCreate?: boolean;
  };
  disabled?: boolean;
  onOptionPicked?: ({ value, label }: { value: any; label: string }) => void;
  onNewOptionPicked?: ({ value, label }: { value: any; label: string }) => void;
  onOptionsChanged?: (options: { value: any; label: string }[]) => void;
  onCurrenOptionDeleted?: () => void;
  customInput?: JSX.Element;
  rows?: number;
  keepValueOnChange?: boolean;
  customSublabel?: string;
  textarea?: boolean;
}

export default function ProfileEditInput(props: ProfileEditInputProps) {
  const { type, label, tooltip, name, customInput } = props;

  return (
    <ProfileEditInputWrapper>
      {label ? (
        <ProfileEditInputLabelContainer>
          <ProfileEditInputLabel>{label}</ProfileEditInputLabel>
          {tooltip ? <ProfileEditInputTooltip tooltip={tooltip} /> : null}
        </ProfileEditInputLabelContainer>
      ) : null}
      {type === 'text' && <Field name={name} as={ProfileEditInputText} {...props} ref={null} />}
      {type === 'select' && <Field name={name} as={ProfileEditInputSelect} {...props} />}
      {type === 'custom' && customInput}
    </ProfileEditInputWrapper>
  );
}

export function ProfileEditInputTooltip({ tooltip }: { tooltip: string }) {
  const tippyInstance = useTippy();

  return (
    <Tippy
      popperOptions={{
        placement: 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [8, 8],
            },
          },
        ],
      }}
      zIndex={999999999999999}
      followCursor
      plugins={[followCursor]}
      placement="bottom-start"
      animation={false}
      trigger="mouseenter"
      onShow={(instance) => {
        tippyInstance.current = instance;
      }}
      onHide={(instance) => {
        tippyInstance.current = null;
      }}
      render={(attrs) => (
        <ProfileEditInputLabelTooltipContainer>
          <ProfileEditInputLabelTooltipText>{tooltip}</ProfileEditInputLabelTooltipText>
        </ProfileEditInputLabelTooltipContainer>
      )}
    >
      <ProfileEditInputLabelTooltipIconContainer>
        <InfoIcon width={22} height={22} fill={'var(--color-data-company-background-hover)'} />
      </ProfileEditInputLabelTooltipIconContainer>
    </Tippy>
  );
}
