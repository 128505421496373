import React, { useContext, useState } from 'react';

import PersonAvatar from 'app/components/CommonStyled/PersonAvatar';
import { ChevronLeft, ChevronRight } from 'app/components/shared/icons';
import useUserData from 'hooks/useUserData';
import { ThemeContext } from 'styled-components';

import {
  MobileMenuProfileBackText,
  MobileMenuProfileInformationCompany,
  MobileMenuProfileInformationContainer,
  MobileMenuProfileInformationName,
  MobileMenuProfileRightContainer,
  MobileMenuProfileWrapper,
} from './styled';

interface MobileMenuProfileProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export default function MobileMenuProfile({ isOpen, setIsOpen }: MobileMenuProfileProps) {
  const userData = useUserData();
  const theme = useContext(ThemeContext);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <MobileMenuProfileWrapper onClick={toggleOpen}>
        <PersonAvatar
          avatar={userData?.profile_image}
          name={userData?.first_name}
          lastName={userData?.last_name}
          size={36}
          extraStyle={{ border: '2px solid rgba(207, 220, 236, 0.5)' }}
        />
        <MobileMenuProfileInformationContainer>
          <MobileMenuProfileInformationName>
            {userData?.first_name} {userData?.last_name}
          </MobileMenuProfileInformationName>
          <MobileMenuProfileInformationCompany>
            {userData?.kyu_company?.name}
          </MobileMenuProfileInformationCompany>
        </MobileMenuProfileInformationContainer>
        <MobileMenuProfileRightContainer>
          {isOpen ? (
            <>
              <ChevronLeft width={5} height={9} fill={'rgba(23, 28, 51, 0.5)'} />
              <MobileMenuProfileBackText>Back</MobileMenuProfileBackText>
            </>
          ) : (
            <ChevronRight width={8} height={14} fill={theme.color} />
          )}
        </MobileMenuProfileRightContainer>
      </MobileMenuProfileWrapper>
    </>
  );
}
