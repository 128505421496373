import styled from 'styled-components';

interface PersonAvatarProps {
  clickable: boolean;
  highlighted: boolean;
  margin: string;
  size: number;
  disabled: boolean;
  withAvatar?: boolean;
  textColor?: string;
  noBorders?: boolean;
  isAlumni?: boolean;
}

interface PersonAvatarSizeProps {
  size: number;
}

export const PersonAvatarContainer = styled.div<PersonAvatarProps>`
  align-items: center;
  background: var(--color-text);
  border-radius: 100%;
  display: flex;
  justify-content: center;

  ${({ clickable, highlighted, margin, size, withAvatar, textColor, noBorders, isAlumni }) => `
    ${
      !noBorders
        ? `
      > img {
        border: 1px solid ${highlighted ? '#ffc229' : '#ADADAD'};
      }
      ${
        isAlumni
          ? `
        position: relative;
        
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          border-radius: 100%;
          background: #000;
          opacity: 0.4;
        }
      `
          : ``
      }
      border: 2px solid ${highlighted ? '#ffc229' : '#ADADAD'};
    `
        : ''
    }

    cursor: ${clickable ? 'pointer' : 'default'};
    height: ${size}px;
    margin: ${margin};
    width: ${size}px;

    > span {
      color: ${textColor ? textColor : highlighted ? '#fff' : '#ADADAD'};
    }
  `}

  position: relative;
`;

export const PersonAvatarText = styled.span<PersonAvatarSizeProps>`
  font-weight: bold;
  font-size: calc(9px + ${({ size }) => size / 10}px);
  line-height: 12px;
  text-align: center;
  width: ${({ size }) => `${size}px`};
`;

export const PersonAvatarImage = styled.img<PersonAvatarSizeProps>`
  border-radius: 100%;
  height: 18px;
  object-fit: cover;
  width: 18px;
  ${({ size }) =>
    `
    height: ${size - 2}px;
    width: ${size - 2}px;
  `}
`;

interface PersonAvatarTippyContainerProps {
  hide: boolean;
}

export const PersonAvatarTippyContainer = styled.div<PersonAvatarTippyContainerProps>`
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px;

  background: #000000;

  z-index: 99999;
`;

export const PersonAvatarTippyName = styled.p`
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
`;

export const PersonAvatarTippyRole = styled.p`
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.5);
`;
