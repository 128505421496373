import React, { useState } from 'react';
import ReactDOMServer from 'react-dom/server';

import GlobalCompanyDrawer from 'app/components/Drawers/GlobalCompany/GlobalCompanyDrawer';
import { getCompanyLogoUrl } from 'utils';

import SideDrawer from '../SideDrawer';
import { CompanyLogoContainer, CompanyLogoEmpty } from './styled';

interface CompanyLogoProps {
  size: number;
  slug: string;
  borderColor?: string;
  additionalStyle?: React.CSSProperties;
  onClick?: () => void;
  useRounded?: boolean;
  backgroundColor?: string;
  onErrorComponent?: JSX.Element;
  openDrawer?: boolean;
  stopPropagation?: boolean;
}

export default function CompanyLogo({
  size,
  slug,
  borderColor,
  additionalStyle = {},
  onClick = null,
  useRounded = false,
  backgroundColor = '#FFFFFF',
  onErrorComponent = null,
  openDrawer = false,
  stopPropagation = true,
}: CompanyLogoProps) {
  const [showDrawer, setShowDrawer] = useState<boolean>(false);

  const handleOpenDrawer = () => {
    setShowDrawer(true);
  };

  const handleCloseDrawer = () => {
    setShowDrawer(false);
  };

  const handleClick = (e: React.MouseEvent<HTMLImageElement | HTMLDivElement, MouseEvent>) => {
    if (stopPropagation) {
      e.stopPropagation();
    }
    if (openDrawer) {
      handleOpenDrawer();
    } else {
      onClick?.();
    }
  };

  if (['pencil-and-paper'].includes(slug)) {
    return (
      <CompanyLogoEmpty
        size={size}
        style={additionalStyle}
        borderColor={borderColor}
        onClick={(e) => handleClick(e)}
        backgroundColor={backgroundColor}
      />
    );
  }

  return (
    <>
      <CompanyLogoContainer
        src={
          useRounded ? `/images/logos/rounded_kyu_companies/${slug}.svg` : getCompanyLogoUrl(slug)
        }
        size={size}
        style={additionalStyle}
        borderColor={borderColor}
        onClick={(e) => handleClick(e)}
        backgroundColor={backgroundColor}
        loading="lazy"
        onError={(e) => {
          if (onErrorComponent) {
            e.currentTarget.outerHTML = ReactDOMServer.renderToString(onErrorComponent);
          } else {
            e.currentTarget.outerHTML = ReactDOMServer.renderToString(<></>);
          }
        }}
      />
      {showDrawer ? (
        <SideDrawer closeDrawer={handleCloseDrawer} withClose>
          <GlobalCompanyDrawer slug={slug} />
        </SideDrawer>
      ) : null}
    </>
  );
}
