import React, { useState } from 'react';

import Tippy from '@tippyjs/react';
import useTippy from 'hooks/useTippy';
import useWindowsResizeWithRef from 'hooks/useWindowsResizeWithRef';

import CompanyLogo from '../CompanyLogo';
import { InfoTooltipTitle, InfoTooltipWrapper } from '../InfoTooltip/styled';
import {
  CompaniesArrayContainer,
  CompaniesArrayMoreCounter,
  CompaniesArrayMoreCounterText,
  CompaniesArrayWrapper,
} from './styled';

interface CompaniesArrayProps {
  companies: any[];
  size?: number;
  borderColor?: string;
  backgroundColor?: string;
  alignCompanies?: 'flex-start' | 'center' | 'flex-end';
  openDrawer?: boolean;
  children?: React.ReactNode;
}

export default function CompaniesArray({
  companies,
  size = 32,
  borderColor = 'var(--color-data-company-foreground)',
  backgroundColor = '#FFFFFF',
  alignCompanies = 'flex-start',
  openDrawer = false,
  children,
}: CompaniesArrayProps) {
  const tippyInstance = useTippy();
  const [rowData, setRowData] = useState<any>({
    companiesPerRow: 0,
    rowsAmount: 0,
  });

  const getRowData = (node: HTMLDivElement) => {
    const { width } = node.getBoundingClientRect();
    const companiesAmount = Math.floor(width / (size - size / 4));
    setRowData({
      companiesPerRow: companiesAmount,
      rowsAmount: companiesAmount !== 0 ? Math.ceil(companies?.length / companiesAmount) : 0,
    });
  };

  const wrapperRef = useWindowsResizeWithRef<HTMLDivElement>((node) => {
    getRowData(node);
  });

  return (
    <CompaniesArrayWrapper ref={wrapperRef} alignCompanies={alignCompanies}>
      {[...Array(rowData.rowsAmount)].map((_, rowIndex) => (
        <CompaniesArrayContainer companyLogoSize={size} key={rowIndex}>
          {children}
          {[...Array(rowData.companiesPerRow)].map((_, companyIndex) => {
            const company = companies[rowIndex * rowData.companiesPerRow + companyIndex];
            if (!company) return null;

            return (
              <Tippy
                key={company.slug}
                popperOptions={{
                  placement: 'bottom',
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [8, 8],
                      },
                    },
                  ],
                }}
                zIndex={999999999999999}
                placement="bottom-start"
                animation={false}
                trigger="mouseenter"
                onShow={(instance) => {
                  tippyInstance.current = instance;
                }}
                onHide={() => {
                  tippyInstance.current = null;
                }}
                render={() => (
                  <InfoTooltipWrapper maxWidth={'100%'}>
                    <InfoTooltipTitle>{company.name}</InfoTooltipTitle>
                  </InfoTooltipWrapper>
                )}
              >
                <div style={{ zIndex: companyIndex }}>
                  <CompanyLogo
                    size={size}
                    slug={company.slug}
                    borderColor={borderColor}
                    backgroundColor={backgroundColor}
                    openDrawer={openDrawer}
                    useRounded
                  />
                </div>
              </Tippy>
            );
          })}
        </CompaniesArrayContainer>
      ))}
    </CompaniesArrayWrapper>
  );
}

CompaniesArray.MoreCounter = function CompaniesArrayMoreCounterComponent({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <CompaniesArrayMoreCounter className="companies-array-more-counter">
      <CompaniesArrayMoreCounterText>{children}</CompaniesArrayMoreCounterText>
    </CompaniesArrayMoreCounter>
  );
};
