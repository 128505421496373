import styled from 'styled-components';

export const NavbarUserDropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 8px;
  padding: 16px;

  border-radius: 0px 0px 24px 24px;
  background: ${({ theme }) => theme.backgroundMenuColor};

  &:before {
    content: '';

    width: 100%;
    height: 100%;

    position: absolute;
    top: 0px;
    left: 0px;

    backdrop-filter: blur(24px);
  }

  & button {
    padding: 8px 16px;
    position: relative;
  }

  position: relative;
`;

export const NavbarUserDropdownInformationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 8px;

  padding: 0px 16px;

  position: relative;
`;

export const NavbarUserDropdownInformationContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 4px;
`;

export const NavbarUserDropdownInformationName = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;

  color: ${({ theme }) => theme.color};
`;

export const NavbarUserDropdownInformationCompany = styled.span`
  max-width: 210px;

  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 1.4px;
  text-transform: uppercase;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;

  color: ${({ theme }) => theme.signOutColor};
`;

//CTA
export const NavbarUserDropdownCTAWrapper = styled.div`
  & > div {
    cursor: pointer;
  }

  cursor: pointer;

  border-radius: 100%;
`;
