import { useSelector } from 'react-redux';

import {
  putBehavioralAboutDrawerCTAClicked,
  putBehavioralAcitivityFeedMapGalleryClicked,
  putBehavioralActivityFeedMeetSomeoneNewClcked,
  putBehavioralActivityFeedRadarActiveWorkClicked,
  putBehavioralActivityFeedRadarEventsClicked,
  putBehavioralActivityFeedRadarPressClicked,
  putBehavioralActivityFeedRadarTrendingClicked,
  putBehavioralActivityFeedRadarTrendingPillClicked,
  putBehavioralActivityFeedSpotlightClicked,
  putBehavioralAnchorTagClicked,
  putBehavioralArticleContributorClicked,
  putBehavioralArticleProfileLinkOutClicked,
  putBehavioralBuildProfileClicked,
  putBehavioralCommonGroundCarouselClicked,
  putBehavioralContinueExploringClicked,
  putBehavioralConveningAttendeeClicked,
  putBehavioralConveningClicked,
  putBehavioralConveningHostClicked,
  putBehavioralDetailsAnnouncementClicked,
  putBehavioralEditProfileClicked,
  putBehavioralEmailCopied,
  putBehavioralFullProfileOpened,
  putBehavioralGlobalDrawerCTAClicked,
  putBehavioralGlobalMapOpen,
  putBehavioralItemClicked,
  putBehavioralLinkedinOpened,
  putBehavioralMapDetailsPageCTAClicked,
  putBehavioralMapLensClicked,
  putBehavioralMapNodeClicked,
  putBehavioralMapOpen,
  putBehavioralMeetSomeoneNewCarouselClicked,
  putBehavioralMeetSomeoneNewViewProfileClicked,
  putBehavioralNavAboutKyuOsClicked,
  putBehavioralNavCommunitiesClicked,
  putBehavioralNavDiscoverClicked,
  putBehavioralNavEventsClicked,
  putBehavioralNavHostAnEventClicked,
  putBehavioralNavNetworkMapsClicked,
  putBehavioralNavPeopleClicked,
  putBehavioralNavPitchAStoryClicked,
  putBehavioralNavProfileProgressBarClicked,
  putBehavioralNavResourcesClicked,
  putBehavioralNavSubmitAResourceClicked,
  putBehavioralNavTheWellClicked,
  putBehavioralNoProjectsClicked,
  putBehavioralPeopleDrawerFilterClicked,
  putBehavioralPeopleDrawerItemClicked,
  putBehavioralPeopleListCompanyDropdownClicked,
  putBehavioralPeopleListDrawerCTAClicked,
  putBehavioralPeopleListFiltersCTAClicked,
  putBehavioralPersonCardClicked,
  putBehavioralPlatformLinkCopied,
  putBehavioralReachCardClicked,
  putBehavioralReactionsListOpened,
  putBehavioralReactionsPersonDrawerCardClicked,
  putBehavioralReactionsProfileSeeAllClicked,
  putBehavioralReactionsViewProfileClicked,
  putBehavioralRegistrationLinkClicked,
  putBehavioralRequestChangeClicked,
  putBehavioralResourceCardCreatorClicked,
  putBehavioralResourceCardSubmitterClicked,
  putBehavioralResourceDetailCreatorClicked,
  putBehavioralResourceDetailsClicked,
  putBehavioralResourceLoadMoreClicked,
  putBehavioralResourceOpen,
  putBehavioralResourceRelatedPageClicked,
  putBehavioralResourceRelatedResourceClicked,
  putBehavioralSearchLensClicked,
  putBehavioralSearchOptionsListItemClicked,
  putBehavioralShareBannerClicked,
  putBehavioralShareButtonClicked,
  putBehavioralSlackOpened,
  putBehavioralTOCClicked,
  putBehavioralTagCategoryClicked,
  putBehavioralTagSearchClicked,
  putBehavioralViewPublicProfileClicked,
  putBehavioralWatchRecapClicked,
  putCollectiveDirectoryOnboardingPromptClicked,
  putCollectiveDirectoryPersonCardClicked,
  putCollectiveDirectorySelectRecipientsClicked,
  putCollectiveDirectoryRefineResultsClicked,
  putCollectiveDirectoryQuickFilterClicked,
  putCollectiveDirectoryProfileCtaClicked,
  putCollectiveDirectorySearchClicked,
  putCollectiveDirectoryFilterMenuClicked,
  putCollectiveDirectoryFilterSectionClicked,
  putCollectiveDirectoryToggleClicked,
  putCollectiveDirectoryLoadMoreClicked,
  putContactFlowSelectAllClicked,
  putContactFlowCopyProfileClicked,
  putContactFlowDeselectProfileClicked,
  putContactFlowPartialMatchSelectionClicked,
  putContactFlowSendClicked,
  putContactFlowBackClicked,
  putContactFlowDismissed,
  putContactFlowEditProfileClicked,
  putBehavioralFeatureBannerDismissed,
  putBehavioralFeatureBannerCTAClicked,
  putBehavioralNavOpened,
  putBehavioralNavHomepageOpened,
  putBehavioralNavSpotlightOpened,
  putBehavioralNavSearchOpened,
  putBehavioralNavProfileMenuOpened,
  putCollectiveDirectoryPersonDrawerFilterClicked,
  putBehavioralSearchLensCollectiveDirectoryCTAClicked,
  putBehavioralPeopleListCollectiveDirectoryCTAClicked,
  putKyu101ProfileEditDrawerOpened,
  putKyu101PublicProfileCtaClicked,
  putKyu101ReachCtaClicked,
  putKyu101PeopleSectionCtaClicked,
  putKyu101CompanyPageCtaClicked,
  putKyu101CompanyClientsClicked,
  putKyu101CompaniesDrawerOpened,
  putKyu101ResourcesOpened,
  putKyu101TheWellOpened,
  putKyu101ConveningsOpened,
  putKyu101UsecasesOpened,
  putKyu101ContactFormOpened,
  putKyu101CasualIntentionCollectiveDirectoryOpened,
  putKyu101ExpertiseIntentionCollectiveDirectoryOpened,
  putKyu101ConveneIntentionCollectiveDirectoryOpened,
  putKyu101ClientsDrawerOpened,
  putKyu101GlobalIndustriesDrawerOpened,
  putKyu101CollectiveDirectoryOpened,
  putKyu101GlobalDisciplineDrawerOpened,
  putKyu101GlobalIndustryDrawerOpened,
  putKyu101GlobalClientDrawerOpened,
  putKyu101GlobalSkillDrawerOpened,
  putKyu101GlobalPassionDrawerOpened,
  putKyu101ResourceLibraryOpened,
  putKyu101FaqClicked,
  putKyu101ModalMinimized,
  putKyu101ModalExpanded,
  putKyu101ModalDismissed,
  putKyu101TocClicked,
} from 'services/behaviourals';
import { behavioralIdSelector } from 'store/reducers/user-reducer';

export const BEHAVIORALS = {
  LINKEDIN_OPENED: 'LINKEDIN_OPENED',
  SLACK_OPENED: 'SLACK_OPENED',
  EMAIL_COPIED: 'EMAIL_COPIED',
  FULL_PROFILE_OPENED: 'FULL_PROFILE_OPENED',
  MAP_OPENED: 'MAP_OPENED',
  PLATFORM_LINK_COPIED: 'PLATFORM_LINK_COPIED',
  SHARE_BANNER_CLICKED: 'SHARE_BANNER_CLICKED',
  GLOBAL_MAP_OPENED: 'GLOBAL_MAP_OPENED',
  CONVENING_CLICKED: 'CONVENING_CLICKED',
  CONVENING_ATTENDEE_CLICKED: 'CONVENING_ATTENDEE_CLICKED',
  CONVENING_HOST_CLICKED: 'CONVENING_HOST_CLICKED',
  REGISTRATION_LINK_CLICKED: 'REGISTRATION_LINK_CLICKED',
  WATCH_RECAP_CLICKED: 'WATCH_RECAP_CLICKED',
  CONTINUE_EXPLORING_CLICKED: 'CONTINUE_EXPLORING_CLICKED',
  ANCHOR_TAG_CLICKED: 'ANCHOR_TAG_CLICKED',
  ITEM_CLICKED: 'ITEM_CLICKED',
  ACTIVITY_FEED_SPOTLIGHT_CLICKED: 'ACTIVITY_FEED_SPOTLIGHT_CLICKED',
  ACTIVITY_FEED_RADAR_TRENDING_CLICKED: 'ACTIVITY_FEED_RADAR_TRENDING_CLICKED',
  ACTIVITY_FEED_RADAR_ACTIVE_WORK_CLICKED: 'ACTIVITY_FEED_RADAR_ACTIVE_WORK_CLICKED',
  ACTIVITY_FEED_RADAR_PRESS_CLICKED: 'ACTIVITY_FEED_RADAR_PRESS_CLICKED',
  ACTIVITY_FEED_RADAR_EVENTS_CLICKED: 'ACTIVITY_FEED_RADAR_EVENTS_CLICKED',
  ACTIVITY_FEED_MEET_SOMEONE_NEW_CLICKED: 'ACTIVITY_FEED_MEET_SOMEONE_NEW_CLICKED',
  ACTIVITY_FEED_MAP_GALLERY_CLICKED: 'ACTIVITY_FEED_MAP_GALLERY_CLICKED',
  ACTIVITY_FEED_RADAR_TRENDING_PILL_CLICKED: 'ACTIVITY_FEED_RADAR_TRENDING_PILL_CLICKED',
  DETAILS_ANNOUNCEMENT_CLICKED: 'DETAILS_ANNOUNCEMENT_CLICKED',
  PERSON_CARD_CLICKED: 'PERSON_CARD_CLICKED',
  ABOUT_DRAWER_CTA_CLICKED: 'ABOUT_DRAWER_CTA_CLICKED',
  TOC_CLICKED: 'TOC_CLICKED',
  PEOPLE_DRAWER_FILTER_CLICKED: 'PEOPLE_DRAWER_FILTER_CLICKED',
  PEOPLE_DRAWER_ITEM_CLICKED: 'PEOPLE_DRAWER_ITEM_CLICKED',
  COMMON_GROUND_CAROUSEL_CLICKED: 'COMMON_GROUND_CAROUSEL_CLICKED',

  //PEOPLE LIST
  PEOPLE_LIST_DRAWER_CTA_CLICKED: 'PEOPLE_LIST_DRAWER_CTA_CLICKED',
  PEOPLE_LIST_FILTERS_CTA_CLICKED: 'PEOPLE_LIST_FILTERS_CTA_CLICKED',
  PEOPLE_LIST_COMPANY_DROPDOWN_CLICKED: 'PEOPLE_LIST_COMPANY_DROPDOWN_CLICKED',
  PEOPLE_LIST_COLLECTIVE_DIRECTORY_CTA_CLICKED: 'PEOPLE_LIST_COLLECTIVE_DIRECTORY_CTA_CLICKED',

  //SEARCH LENSES
  SEARCH_LENS_CLICKED: 'SEARCH_LENS_CLICKED',
  SEARCH_LENS_COLLECTIVE_DIRECTORY_CTA_CLICKED: 'SEARCH_LENS_COLLECTIVE_DIRECTORY_CTA_CLICKED',

  //NAV
  NAV_ABOUT_KYU_OS: 'NAV_ABOUT_KYU_OS',
  NAV_NETWORK_MAPS: 'NAV_NETWORK_MAPS',
  NAV_THE_WELL: 'NAV_THE_WELL',
  NAV_COMMUNITIES: 'NAV_COMMUNITIES',
  NAV_EVENTS: 'NAV_EVENTS',
  NAV_DISCOVER: 'NAV_DISCOVER',
  NAV_HOST_AN_EVENT: 'NAV_HOST_AN_EVENT',
  NAV_PITCH_A_STORY: 'NAV_PITCH_A_STORY',
  NAV_SUBMIT_A_RESOURCE: 'NAV_SUBMIT_A_RESOURCE',
  NAV_RESOURCES: 'NAV_RESOURCES',
  NAV_PEOPLE: 'NAV_PEOPLE',
  NAV_OPENED: 'NAV_OPENED',
  NAV_HOMEPAGE_OPENED: 'NAV_HOMEPAGE_OPENED',
  NAV_SPOTLIGHT_OPENED: 'NAV_SPOTLIGHT_OPENED',
  NAV_SEARCH_OPENED: 'NAV_SEARCH_OPENED',
  NAV_PROFILE_MENU_OPENED: 'NAV_PROFILE_MENU_OPENED',

  // RESOURCES
  RESOURCE_DETAIL_CLICKED: 'RESOURCE_DETAIL_CLICKED',
  RESOURCE_TAG_CATEGORY_CLICKED: 'RESOURCE_TAG_CATEGORY_CLICKED',
  RESOURCE_TAG_SEARCH_CLICKED: 'RESOURCE_TAG_SEARCH_CLICKED',
  RESOURCE_RELATED_RESOURCE_CLICKED: 'RESOURCE_RELATED_RESOURCE_CLICKED',
  RESOURCE_RELATED_PAGE_CLICKED: 'RESOURCE_RELATED_PAGE_CLICKED',
  RESOURCE_LOAD_MORE_CLICKED: 'RESOURCE_LOAD_MORE_CLICKED',
  RESOURCE_OPENED: 'RESOURCE_OPENED',
  RESOURCE_CARD_SUBMITTER_CLICKED: 'RESOURCE_CARD_SUBMITTER_CLICKED',
  RESOURCE_CARD_CREATOR_CLICKED: 'RESOURCE_CARD_CREATOR_CLICKED',
  RESOURCE_DETAIL_CREATOR_CLICKED: 'RESOURCE_DETAIL_CREATOR_CLICKED',

  // DRAWER
  SEARCH_OPTIONS_LIST_ITEM_CLICKED: 'SEARCH_OPTIONS_LIST_ITEM_CLICKED',
  GLOBAL_DRAWER_CTA_CLICKED: 'GLOBAL_DRAWER_CTA_CLICKED',

  // MEET SOMEONE NEW
  MEET_SOMEONE_NEW_CAROUSEL_CLICKED: 'MEET_SOMEONE_NEW_CAROUSEL_CLICKED',
  MEET_SOMEONE_NEW_CAROUSEL_VIEW_PROFILE_CLICKED: 'MEET_SOMEONE_NEW_CAROUSEL_VIEW_PROFILE_CLICKED',

  // MAPS
  MAP_LENS_CLICKED: 'MAP_LENS_CLICKED',
  MAP_NODE_CLICKED: 'MAP_NODE_CLICKED',
  MAP_DETAILS_PAGE_CTA_CLICKED: 'MAP_DETAILS_PAGE_CTA_CLICKED',

  //PROFILE
  NAV_PROFILE_PROGRESS_BAR_CLICKED: 'NAV_PROFILE_PROGRESS_BAR_CLICKED',
  VIEW_PUBLIC_PROFILE_CLICKED: 'VIEW_PUBLIC_PROFILE_CLICKED',
  EDIT_PROFILE_CLICKED: 'EDIT_PROFILE_CLICKED',
  REACH_CARD_CLICKED: 'REACH_CARD_CLICKED',
  BUILD_PROFILE_CLICKED: 'BUILD_PROFILE_CLICKED',
  NO_PROJECTS_CLICKED: 'NO_PROJECTS_CLICKED',
  REQUEST_CHANGE_CLICKED: 'REQUEST_CHANGE_CLICKED',

  //ARTICLE
  ARTICLE_CONTRIBUTOR_CLICKED: 'ARTICLE_CONTRIBUTOR_CLICKED',
  ARTICLE_PROFILE_LINK_OUT_CLICKED: 'ARTICLE_PROFILE_LINK_OUT_CLICKED',

  //REACTIONS
  SHARE_BUTTON_CLICKED: 'SHARE_BUTTON_CLICKED',
  REACTIONS_LIST_OPENED: 'REACTIONS_LIST_OPENED',
  REACTIONS_VIEW_PROFILE_CLICKED: 'REACTIONS_VIEW_PROFILE_CLICKED',
  REACTIONS_PROFILE_SEE_ALL_CLICKED: 'REACTIONS_PROFILE_SEE_ALL_CLICKED',
  REACTIONS_PERSON_DRAWER_CARD_CLICKED: 'REACTIONS_PERSON_DRAWER_CARD_CLICKED',

  //BANNER
  FEATURE_BANNER_DISMISSED: 'FEATURE_BANNER_DISMISSED',
  FEATURE_BANNER_CTA_CLICKED: 'FEATURE_BANNER_CTA_CLICKED',

  //COLLECTIVE DIRECTORY ONBOARDING
  COLLECTIVE_DIRECTORY_ONBOARDING_PROMPT_CLICKED: 'COLLECTIVE_DIRECTORY_ONBOARDING_PROMPT_CLICKED',

  //COLLECTIVE DIRECTORY
  COLLECTIVE_DIRECTORY_PERSON_CARD_CLICKED: 'COLLECTIVE_DIRECTORY_PERSON_CARD_CLICKED',
  COLLECTIVE_DIRECTORY_SELECT_RECIPIENTS_CLICKED: 'COLLECTIVE_DIRECTORY_SELECT_RECIPIENTS_CLICKED',
  COLLECTIVE_DIRECTORY_REFINE_RESULTS_CLICKED: 'COLLECTIVE_DIRECTORY_REFINE_RESULTS_CLICKED',
  COLLECTIVE_DIRECTORY_QUICK_FILTER_CLICKED: 'COLLECTIVE_DIRECTORY_QUICK_FILTER_CLICKED',
  COLLECTIVE_DIRECTORY_PROFILE_CTA_CLICKED: 'COLLECTIVE_DIRECTORY_PROFILE_CTA_CLICKED',
  COLLECTIVE_DIRECTORY_SEARCH_RESULT_CLICKED: 'COLLECTIVE_DIRECTORY_SEARCH_RESULT_CLICKED',
  COLLECTIVE_DIRECTORY_FILTER_MENU_CLICKED: 'COLLECTIVE_DIRECTORY_FILTER_MENU_CLICKED',
  COLLECTIVE_DIRECTORY_FILTER_SECTION_CLICKED: 'COLLECTIVE_DIRECTORY_FILTER_SECTION_CLICKED',
  COLLECTIVE_DIRECTORY_TOGGLE_CLICKED: 'COLLECTIVE_DIRECTORY_TOGGLE_CLICKED',
  COLLECTIVE_DIRECTORY_LOAD_MORE_CLICKED: 'COLLECTIVE_DIRECTORY_LOAD_MORE_CLICKED',
  COLLECTIVE_DIRECTORY_PERSON_DRAWER_FILTER_CLICKED:
    'COLLECTIVE_DIRECTORY_PERSON_DRAWER_FILTER_CLICKED',

  //CONTACT FLOW
  CONTACT_FLOW_SELECT_ALL_CLICKED: 'CONTACT_FLOW_SELECT_ALL_CLICKED',
  CONTACT_FLOW_COPY_PROFILE_CLICKED: 'CONTACT_FLOW_COPY_PROFILE_CLICKED',
  CONTACT_FLOW_DESELECT_PROFILE_CLICKED: 'CONTACT_FLOW_DESELECT_PROFILE_CLICKED',
  CONTACT_FLOW_PARTIAL_MATCH_SELECTION_CLICKED: 'CONTACT_FLOW_PARTIAL_MATCH_SELECTION_CLICKED',
  CONTACT_FLOW_SEND_CLICKED: 'CONTACT_FLOW_SEND_CLICKED',
  CONTACT_FLOW_BACK_CLICKED: 'CONTACT_FLOW_BACK_CLICKED',
  CONTACT_FLOW_DISMISSED: 'CONTACT_FLOW_DISMISSED',
  CONTACT_FLOW_EDIT_PROFILE_CLICKED: 'CONTACT_FLOW_EDIT_PROFILE_CLICKED',

  //ABOUT KYU OS
  KYU101_PROFILE_EDIT_DRAWER_OPENED: 'KYU101_PROFILE_EDIT_DRAWER_OPENED',
  KYU101_PUBLIC_PROFILE_CTA_CLICKED: 'KYU101_PUBLIC_PROFILE_CTA_CLICKED',
  KYU101_REACH_CTA_CLICKED: 'KYU101_REACH_CTA_CLICKED',
  KYU101_PEOPLE_SECTION_CTA_CLICKED: 'KYU101_PEOPLE_SECTION_CTA_CLICKED',
  KYU101_COMPANY_PAGE_CTA_CLICKED: 'KYU101_COMPANY_PAGE_CTA_CLICKED',
  KYU101_COMPANY_CLIENTS_CLICKED: 'KYU101_COMPANY_CLIENTS_CLICKED',
  KYU101_COMPANIES_DRAWER_OPENED: 'KYU101_COMPANIES_DRAWER_OPENED',
  KYU101_RESOURCES_OPENED: 'KYU101_RESOURCES_OPENED',
  KYU101_THE_WELL_OPENED: 'KYU101_THE_WELL_OPENED',
  KYU101_CONVENINGS_OPENED: 'KYU101_CONVENINGS_OPENED',
  KYU101_USECASES_OPENED: 'KYU101_USECASES_OPENED',
  KYU101_CONTACT_FORM_OPENED: 'KYU101_CONTACT_FORM_OPENED',
  KYU101_CASUAL_INTENTION_COLLECTIVE_DIRECTORY_OPENED:
    'KYU101_CASUAL_INTENTION_COLLECTIVE_DIRECTORY_OPENED',
  KYU101_EXPERTISE_INTENTION_COLLECTIVE_DIRECTORY_OPENED:
    'KYU101_EXPERTISE_INTENTION_COLLECTIVE_DIRECTORY_OPENED',
  KYU101_CONVENE_INTENTION_COLLECTIVE_DIRECTORY_OPENED:
    'KYU101_CONVENE_INTENTION_COLLECTIVE_DIRECTORY_OPENED',
  KYU101_CLIENTS_DRAWER_OPENED: 'KYU101_CLIENTS_DRAWER_OPENED',
  KYU101_GLOBAL_INDUSTRIES_DRAWER_OPENED: 'KYU101_GLOBAL_INDUSTRIES_DRAWER_OPENED',
  KYU101_COLLECTIVE_DIRECTORY_OPENED: 'KYU101_COLLECTIVE_DIRECTORY_OPENED',
  KYU101_GLOBAL_DISCIPLINE_DRAWER_OPENED: 'KYU101_GLOBAL_DISCIPLINE_DRAWER_OPENED',
  KYU101_GLOBAL_INDUSTRY_DRAWER_OPENED: 'KYU101_GLOBAL_INDUSTRY_DRAWER_OPENED',
  KYU101_GLOBAL_CLIENT_DRAWER_OPENED: 'KYU101_GLOBAL_CLIENT_DRAWER_OPENED',
  KYU101_GLOBAL_SKILL_DRAWER_OPENED: 'KYU101_GLOBAL_SKILL_DRAWER_OPENED',
  KYU101_GLOBAL_PASSION_DRAWER_OPENED: 'KYU101_GLOBAL_PASSION_DRAWER_OPENED',
  KYU101_RESOURCE_LIBRARY_OPENED: 'KYU101_RESOURCE_LIBRARY_OPENED',
  KYU101_FAQ_CLICKED: 'KYU101_FAQ_CLICKED',
  KYU101_MODAL_MINIMIZED: 'KYU101_MODAL_MINIMIZED',
  KYU101_MODAL_EXPANDED: 'KYU101_MODAL_EXPANDED',
  KYU101_MODAL_DISMISSED: 'KYU101_MODAL_DISMISSED',
  KYU101_TOC_CLICKED: 'KYU101_TOC_CLICKED',
} as const;

export type BEHAVIORALS = (typeof BEHAVIORALS)[keyof typeof BEHAVIORALS];

const BEHAVIORAL_TO_SERVICE = new Map<BEHAVIORALS, Function>([
  [BEHAVIORALS.FULL_PROFILE_OPENED, putBehavioralFullProfileOpened],
  [BEHAVIORALS.LINKEDIN_OPENED, putBehavioralLinkedinOpened],
  [BEHAVIORALS.SLACK_OPENED, putBehavioralSlackOpened],
  [BEHAVIORALS.EMAIL_COPIED, putBehavioralEmailCopied],
  [BEHAVIORALS.MAP_OPENED, putBehavioralMapOpen],
  [BEHAVIORALS.PLATFORM_LINK_COPIED, putBehavioralPlatformLinkCopied],
  [BEHAVIORALS.SHARE_BANNER_CLICKED, putBehavioralShareBannerClicked],
  [BEHAVIORALS.GLOBAL_MAP_OPENED, putBehavioralGlobalMapOpen],
  [BEHAVIORALS.CONVENING_CLICKED, putBehavioralConveningClicked],
  [BEHAVIORALS.REGISTRATION_LINK_CLICKED, putBehavioralRegistrationLinkClicked],
  [BEHAVIORALS.WATCH_RECAP_CLICKED, putBehavioralWatchRecapClicked],
  [BEHAVIORALS.CONTINUE_EXPLORING_CLICKED, putBehavioralContinueExploringClicked],
  [BEHAVIORALS.ANCHOR_TAG_CLICKED, putBehavioralAnchorTagClicked],
  [BEHAVIORALS.ITEM_CLICKED, putBehavioralItemClicked],
  [BEHAVIORALS.NAV_ABOUT_KYU_OS, putBehavioralNavAboutKyuOsClicked],
  [BEHAVIORALS.NAV_NETWORK_MAPS, putBehavioralNavNetworkMapsClicked],
  [BEHAVIORALS.NAV_THE_WELL, putBehavioralNavTheWellClicked],
  [BEHAVIORALS.NAV_COMMUNITIES, putBehavioralNavCommunitiesClicked],
  [BEHAVIORALS.NAV_RESOURCES, putBehavioralNavResourcesClicked],
  [BEHAVIORALS.NAV_PEOPLE, putBehavioralNavPeopleClicked],
  [BEHAVIORALS.NAV_EVENTS, putBehavioralNavEventsClicked],
  [BEHAVIORALS.NAV_DISCOVER, putBehavioralNavDiscoverClicked],
  [BEHAVIORALS.NAV_HOST_AN_EVENT, putBehavioralNavHostAnEventClicked],
  [BEHAVIORALS.NAV_PITCH_A_STORY, putBehavioralNavPitchAStoryClicked],
  [BEHAVIORALS.NAV_SUBMIT_A_RESOURCE, putBehavioralNavSubmitAResourceClicked],
  [BEHAVIORALS.NAV_OPENED, putBehavioralNavOpened],
  [BEHAVIORALS.NAV_HOMEPAGE_OPENED, putBehavioralNavHomepageOpened],
  [BEHAVIORALS.NAV_SPOTLIGHT_OPENED, putBehavioralNavSpotlightOpened],
  [BEHAVIORALS.NAV_SEARCH_OPENED, putBehavioralNavSearchOpened],
  [BEHAVIORALS.NAV_PROFILE_MENU_OPENED, putBehavioralNavProfileMenuOpened],
  [BEHAVIORALS.ACTIVITY_FEED_SPOTLIGHT_CLICKED, putBehavioralActivityFeedSpotlightClicked],
  [BEHAVIORALS.ACTIVITY_FEED_RADAR_TRENDING_CLICKED, putBehavioralActivityFeedRadarTrendingClicked],
  [
    BEHAVIORALS.ACTIVITY_FEED_RADAR_TRENDING_PILL_CLICKED,
    putBehavioralActivityFeedRadarTrendingPillClicked,
  ],
  [
    BEHAVIORALS.ACTIVITY_FEED_RADAR_ACTIVE_WORK_CLICKED,
    putBehavioralActivityFeedRadarActiveWorkClicked,
  ],
  [BEHAVIORALS.ACTIVITY_FEED_RADAR_PRESS_CLICKED, putBehavioralActivityFeedRadarPressClicked],
  [BEHAVIORALS.ACTIVITY_FEED_RADAR_EVENTS_CLICKED, putBehavioralActivityFeedRadarEventsClicked],
  [
    BEHAVIORALS.ACTIVITY_FEED_MEET_SOMEONE_NEW_CLICKED,
    putBehavioralActivityFeedMeetSomeoneNewClcked,
  ],
  [BEHAVIORALS.ACTIVITY_FEED_MAP_GALLERY_CLICKED, putBehavioralAcitivityFeedMapGalleryClicked],
  [BEHAVIORALS.DETAILS_ANNOUNCEMENT_CLICKED, putBehavioralDetailsAnnouncementClicked],
  [BEHAVIORALS.RESOURCE_DETAIL_CLICKED, putBehavioralResourceDetailsClicked],
  [BEHAVIORALS.RESOURCE_TAG_CATEGORY_CLICKED, putBehavioralTagCategoryClicked],
  [BEHAVIORALS.RESOURCE_TAG_SEARCH_CLICKED, putBehavioralTagSearchClicked],
  [BEHAVIORALS.RESOURCE_RELATED_RESOURCE_CLICKED, putBehavioralResourceRelatedResourceClicked],
  [BEHAVIORALS.RESOURCE_RELATED_PAGE_CLICKED, putBehavioralResourceRelatedPageClicked],
  [BEHAVIORALS.RESOURCE_LOAD_MORE_CLICKED, putBehavioralResourceLoadMoreClicked],
  [BEHAVIORALS.RESOURCE_OPENED, putBehavioralResourceOpen],
  [BEHAVIORALS.SEARCH_LENS_CLICKED, putBehavioralSearchLensClicked],
  [
    BEHAVIORALS.SEARCH_LENS_COLLECTIVE_DIRECTORY_CTA_CLICKED,
    putBehavioralSearchLensCollectiveDirectoryCTAClicked,
  ],
  [BEHAVIORALS.SEARCH_OPTIONS_LIST_ITEM_CLICKED, putBehavioralSearchOptionsListItemClicked],
  [BEHAVIORALS.GLOBAL_DRAWER_CTA_CLICKED, putBehavioralGlobalDrawerCTAClicked],
  [BEHAVIORALS.PERSON_CARD_CLICKED, putBehavioralPersonCardClicked],
  [BEHAVIORALS.PEOPLE_LIST_DRAWER_CTA_CLICKED, putBehavioralPeopleListDrawerCTAClicked],
  [BEHAVIORALS.PEOPLE_LIST_FILTERS_CTA_CLICKED, putBehavioralPeopleListFiltersCTAClicked],
  [BEHAVIORALS.MEET_SOMEONE_NEW_CAROUSEL_CLICKED, putBehavioralMeetSomeoneNewCarouselClicked],
  [
    BEHAVIORALS.MEET_SOMEONE_NEW_CAROUSEL_VIEW_PROFILE_CLICKED,
    putBehavioralMeetSomeoneNewViewProfileClicked,
  ],
  [BEHAVIORALS.PEOPLE_LIST_COMPANY_DROPDOWN_CLICKED, putBehavioralPeopleListCompanyDropdownClicked],
  [
    BEHAVIORALS.PEOPLE_LIST_COLLECTIVE_DIRECTORY_CTA_CLICKED,
    putBehavioralPeopleListCollectiveDirectoryCTAClicked,
  ],
  [BEHAVIORALS.ABOUT_DRAWER_CTA_CLICKED, putBehavioralAboutDrawerCTAClicked],
  [BEHAVIORALS.TOC_CLICKED, putBehavioralTOCClicked],
  [BEHAVIORALS.PEOPLE_DRAWER_FILTER_CLICKED, putBehavioralPeopleDrawerFilterClicked],
  [BEHAVIORALS.PEOPLE_DRAWER_ITEM_CLICKED, putBehavioralPeopleDrawerItemClicked],
  [BEHAVIORALS.COMMON_GROUND_CAROUSEL_CLICKED, putBehavioralCommonGroundCarouselClicked],
  [BEHAVIORALS.MAP_LENS_CLICKED, putBehavioralMapLensClicked],
  [BEHAVIORALS.MAP_NODE_CLICKED, putBehavioralMapNodeClicked],
  [BEHAVIORALS.MAP_DETAILS_PAGE_CTA_CLICKED, putBehavioralMapDetailsPageCTAClicked],

  [BEHAVIORALS.NAV_PROFILE_PROGRESS_BAR_CLICKED, putBehavioralNavProfileProgressBarClicked],
  [BEHAVIORALS.VIEW_PUBLIC_PROFILE_CLICKED, putBehavioralViewPublicProfileClicked],
  [BEHAVIORALS.EDIT_PROFILE_CLICKED, putBehavioralEditProfileClicked],
  [BEHAVIORALS.REACH_CARD_CLICKED, putBehavioralReachCardClicked],
  [BEHAVIORALS.BUILD_PROFILE_CLICKED, putBehavioralBuildProfileClicked],
  [BEHAVIORALS.NO_PROJECTS_CLICKED, putBehavioralNoProjectsClicked],
  [BEHAVIORALS.REQUEST_CHANGE_CLICKED, putBehavioralRequestChangeClicked],
  [BEHAVIORALS.RESOURCE_CARD_SUBMITTER_CLICKED, putBehavioralResourceCardSubmitterClicked],
  [BEHAVIORALS.RESOURCE_CARD_CREATOR_CLICKED, putBehavioralResourceCardCreatorClicked],
  [BEHAVIORALS.RESOURCE_DETAIL_CREATOR_CLICKED, putBehavioralResourceDetailCreatorClicked],
  [BEHAVIORALS.ARTICLE_CONTRIBUTOR_CLICKED, putBehavioralArticleContributorClicked],
  [BEHAVIORALS.ARTICLE_PROFILE_LINK_OUT_CLICKED, putBehavioralArticleProfileLinkOutClicked],
  [BEHAVIORALS.CONVENING_ATTENDEE_CLICKED, putBehavioralConveningAttendeeClicked],
  [BEHAVIORALS.CONVENING_HOST_CLICKED, putBehavioralConveningHostClicked],
  [BEHAVIORALS.SHARE_BUTTON_CLICKED, putBehavioralShareButtonClicked],
  [BEHAVIORALS.REACTIONS_LIST_OPENED, putBehavioralReactionsListOpened],
  [BEHAVIORALS.REACTIONS_VIEW_PROFILE_CLICKED, putBehavioralReactionsViewProfileClicked],
  [BEHAVIORALS.REACTIONS_PROFILE_SEE_ALL_CLICKED, putBehavioralReactionsProfileSeeAllClicked],
  [BEHAVIORALS.REACTIONS_PERSON_DRAWER_CARD_CLICKED, putBehavioralReactionsPersonDrawerCardClicked],
  [
    BEHAVIORALS.COLLECTIVE_DIRECTORY_ONBOARDING_PROMPT_CLICKED,
    putCollectiveDirectoryOnboardingPromptClicked,
  ],
  [BEHAVIORALS.COLLECTIVE_DIRECTORY_PERSON_CARD_CLICKED, putCollectiveDirectoryPersonCardClicked],
  [
    BEHAVIORALS.COLLECTIVE_DIRECTORY_SELECT_RECIPIENTS_CLICKED,
    putCollectiveDirectorySelectRecipientsClicked,
  ],
  [
    BEHAVIORALS.COLLECTIVE_DIRECTORY_REFINE_RESULTS_CLICKED,
    putCollectiveDirectoryRefineResultsClicked,
  ],
  [BEHAVIORALS.COLLECTIVE_DIRECTORY_QUICK_FILTER_CLICKED, putCollectiveDirectoryQuickFilterClicked],
  [BEHAVIORALS.COLLECTIVE_DIRECTORY_PROFILE_CTA_CLICKED, putCollectiveDirectoryProfileCtaClicked],
  [BEHAVIORALS.COLLECTIVE_DIRECTORY_SEARCH_RESULT_CLICKED, putCollectiveDirectorySearchClicked],
  [BEHAVIORALS.COLLECTIVE_DIRECTORY_FILTER_MENU_CLICKED, putCollectiveDirectoryFilterMenuClicked],
  [
    BEHAVIORALS.COLLECTIVE_DIRECTORY_FILTER_SECTION_CLICKED,
    putCollectiveDirectoryFilterSectionClicked,
  ],
  [BEHAVIORALS.COLLECTIVE_DIRECTORY_TOGGLE_CLICKED, putCollectiveDirectoryToggleClicked],
  [BEHAVIORALS.COLLECTIVE_DIRECTORY_LOAD_MORE_CLICKED, putCollectiveDirectoryLoadMoreClicked],
  [
    BEHAVIORALS.COLLECTIVE_DIRECTORY_PERSON_DRAWER_FILTER_CLICKED,
    putCollectiveDirectoryPersonDrawerFilterClicked,
  ],
  [BEHAVIORALS.CONTACT_FLOW_SELECT_ALL_CLICKED, putContactFlowSelectAllClicked],
  [BEHAVIORALS.CONTACT_FLOW_COPY_PROFILE_CLICKED, putContactFlowCopyProfileClicked],
  [BEHAVIORALS.CONTACT_FLOW_DESELECT_PROFILE_CLICKED, putContactFlowDeselectProfileClicked],
  [
    BEHAVIORALS.CONTACT_FLOW_PARTIAL_MATCH_SELECTION_CLICKED,
    putContactFlowPartialMatchSelectionClicked,
  ],
  [BEHAVIORALS.CONTACT_FLOW_SEND_CLICKED, putContactFlowSendClicked],
  [BEHAVIORALS.CONTACT_FLOW_BACK_CLICKED, putContactFlowBackClicked],
  [BEHAVIORALS.CONTACT_FLOW_DISMISSED, putContactFlowDismissed],
  [BEHAVIORALS.CONTACT_FLOW_EDIT_PROFILE_CLICKED, putContactFlowEditProfileClicked],
  [BEHAVIORALS.FEATURE_BANNER_DISMISSED, putBehavioralFeatureBannerDismissed],
  [BEHAVIORALS.FEATURE_BANNER_CTA_CLICKED, putBehavioralFeatureBannerCTAClicked],
  [BEHAVIORALS.KYU101_PROFILE_EDIT_DRAWER_OPENED, putKyu101ProfileEditDrawerOpened],
  [BEHAVIORALS.KYU101_PUBLIC_PROFILE_CTA_CLICKED, putKyu101PublicProfileCtaClicked],
  [BEHAVIORALS.KYU101_REACH_CTA_CLICKED, putKyu101ReachCtaClicked],
  [BEHAVIORALS.KYU101_PEOPLE_SECTION_CTA_CLICKED, putKyu101PeopleSectionCtaClicked],
  [BEHAVIORALS.KYU101_COMPANY_PAGE_CTA_CLICKED, putKyu101CompanyPageCtaClicked],
  [BEHAVIORALS.KYU101_COMPANY_CLIENTS_CLICKED, putKyu101CompanyClientsClicked],
  [BEHAVIORALS.KYU101_COMPANIES_DRAWER_OPENED, putKyu101CompaniesDrawerOpened],
  [BEHAVIORALS.KYU101_RESOURCES_OPENED, putKyu101ResourcesOpened],
  [BEHAVIORALS.KYU101_THE_WELL_OPENED, putKyu101TheWellOpened],
  [BEHAVIORALS.KYU101_CONVENINGS_OPENED, putKyu101ConveningsOpened],
  [BEHAVIORALS.KYU101_USECASES_OPENED, putKyu101UsecasesOpened],
  [BEHAVIORALS.KYU101_CONTACT_FORM_OPENED, putKyu101ContactFormOpened],
  [
    BEHAVIORALS.KYU101_CASUAL_INTENTION_COLLECTIVE_DIRECTORY_OPENED,
    putKyu101CasualIntentionCollectiveDirectoryOpened,
  ],
  [
    BEHAVIORALS.KYU101_EXPERTISE_INTENTION_COLLECTIVE_DIRECTORY_OPENED,
    putKyu101ExpertiseIntentionCollectiveDirectoryOpened,
  ],
  [
    BEHAVIORALS.KYU101_CONVENE_INTENTION_COLLECTIVE_DIRECTORY_OPENED,
    putKyu101ConveneIntentionCollectiveDirectoryOpened,
  ],
  [BEHAVIORALS.KYU101_CLIENTS_DRAWER_OPENED, putKyu101ClientsDrawerOpened],
  [BEHAVIORALS.KYU101_GLOBAL_INDUSTRIES_DRAWER_OPENED, putKyu101GlobalIndustriesDrawerOpened],
  [BEHAVIORALS.KYU101_COLLECTIVE_DIRECTORY_OPENED, putKyu101CollectiveDirectoryOpened],
  [BEHAVIORALS.KYU101_GLOBAL_DISCIPLINE_DRAWER_OPENED, putKyu101GlobalDisciplineDrawerOpened],
  [BEHAVIORALS.KYU101_GLOBAL_INDUSTRY_DRAWER_OPENED, putKyu101GlobalIndustryDrawerOpened],
  [BEHAVIORALS.KYU101_GLOBAL_CLIENT_DRAWER_OPENED, putKyu101GlobalClientDrawerOpened],
  [BEHAVIORALS.KYU101_GLOBAL_SKILL_DRAWER_OPENED, putKyu101GlobalSkillDrawerOpened],
  [BEHAVIORALS.KYU101_GLOBAL_PASSION_DRAWER_OPENED, putKyu101GlobalPassionDrawerOpened],
  [BEHAVIORALS.KYU101_RESOURCE_LIBRARY_OPENED, putKyu101ResourceLibraryOpened],
  [BEHAVIORALS.KYU101_FAQ_CLICKED, putKyu101FaqClicked],
  [BEHAVIORALS.KYU101_MODAL_MINIMIZED, putKyu101ModalMinimized],
  [BEHAVIORALS.KYU101_MODAL_EXPANDED, putKyu101ModalExpanded],
  [BEHAVIORALS.KYU101_MODAL_DISMISSED, putKyu101ModalDismissed],
  [BEHAVIORALS.KYU101_TOC_CLICKED, putKyu101TocClicked],
]);

export default function useBehavioral(behavioral: BEHAVIORALS, extraParams?: any) {
  const behavioralId = useSelector(behavioralIdSelector);

  const behavioralFunction = (moreParams = {}) => {
    if ((extraParams?.behavioralId || behavioralId) && BEHAVIORAL_TO_SERVICE.has(behavioral)) {
      BEHAVIORAL_TO_SERVICE.get(behavioral).apply(null, [
        {
          behavioral_id: extraParams?.behavioralId || behavioralId,
          ...extraParams,
          ...moreParams,
        },
      ]);
    }
  };

  return behavioralFunction;
}
