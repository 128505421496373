import styled from 'styled-components';

export const ResourceCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.1);

  cursor: pointer;
`;

interface ResourceCardContainerProps {
  withLikes: boolean;
}

export const ResourceCardContainer = styled.div<ResourceCardContainerProps>`
  display: flex;
  flex-direction: column;

  padding: 16px;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }

  background: #ffffff;
  position: relative;
`;

export const ResourceCardPaywallContent = styled.div`
  position: absolute;
  bottom: 16px;
  right: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 3px 6px;
  border-radius: 4px;
  background: var(--color-data-company-background-secondary);
`;

export const ResourceCardIconContainer = styled.div`
  width: 40px;
  height: 40px;

  position: absolute;
  top: 8px;
  right: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 100%;
  background: var(--color-primary);
  z-index: 2;
`;

export const ResourceCardImageContainer = styled.div`
  position: relative;
  overflow: hidden;
`;

export const ResourceCardLikesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  & > :not(:last-child) {
    margin-right: 2px;
  }
`;

export const ResourceCardImageLikesContainer = styled.div`
  width: fit-content;

  position: absolute;
  top: 0px;
  left: 0px;

  display: flex;
  flex-direction: row;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 2px;
  }

  padding: 4px 8px;

  border-radius: 0px 0px 8px 0px;
  background: #fff;
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.1);
`;

export const ResourceCardImageLikesText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  color: #323f5d;
`;

export const ResourceCardImage = styled.img`
  width: 100%;
  height: 144px;
  object-fit: cover;
`;

export const ResourceCardTitle = styled.span`
  max-width: calc(100% - 40px);
  font-weight: 400;
  font-size: 18px;
  line-height: normal;
  color: var(--color-text);
`;

export const ResourceCardDescription = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  color: rgba(23, 28, 51, 0.5);

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`;

export const ResourceCardTagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
`;

export const ResourceCardTag = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  color: var(--color-text);

  padding: 2px 8px;

  border-radius: 28px;
  background: rgba(23, 28, 51, 0.1);

  cursor: pointer;
`;

export const ResourceCardLinkAndPaywallContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ResourceCardLinkContainer = styled.div`
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 8px;
  }
`;

export const ResourceCardLinkFavicon = styled.img`
  width: 16px;
  height: 16px;
  object-fit: cover;
`;

export const ResourceCardLink = styled.a`
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
  color: rgba(23, 28, 51, 0.5);
  text-decoration-line: underline;
`;

export const ResourceCardExtraInformationWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 8px 16px;

  & > :not(:last-child) {
    margin-bottom: 4px;
  }

  background: #f7f7f7;
`;

export const ResourceCardExtraInformationRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > :not(:last-child) {
    margin-right: 4px;
  }
`;

export const ResourceCardExtraInformationCreatedBy = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: normal;

  color: rgba(23, 28, 51, 0.3);
`;

export const ResourceCardExtraInformationDateLabel = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: normal;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: rgba(23, 28, 51, 0.3);
`;

// RESOURCE CARD PEOPLE
export const ResourceCardPeopleWrapper = styled.div`
  min-width: 50%;
`;
interface ResourceCardPeopleContainerProps {
  hasMore: boolean;
}

export const ResourceCardPeopleContainer = styled(
  ResourceCardPeopleWrapper
)<ResourceCardPeopleContainerProps>`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;

  & > :not(:first-child) {
    margin-right: -8px;
  }

  ${({ hasMore }) =>
    hasMore &&
    `
    & > :first-child {
      margin-left: 3px;
    }
  `}
`;

export const ResourceCardPeopleCounterContainer = styled.div`
  width: 22px;
  height: 22px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 100%;
  border: 2px solid var(--color-data-people-foreground);
  background: var(--color-data-people-background-secondary);
`;

export const ResourceCardPeopleCounter = styled.span`
  font-weight: 700;
  font-size: 11px;
  line-height: normal;
  text-align: center;
  color: var(--color-data-people-foreground);
`;

export const ResourceCardPeopleSinglePersonContainer = styled(ResourceCardPeopleWrapper)`
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 4px;
  }
`;

export const ResourceCardPeopleSinglePersonName = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  color: var(--color-text);
`;

// RESOURCE CARD COMPANIES
export const ResourceCardCompaniesKyuContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px 12px;
  border-radius: 32px;
  background: var(--color-primary);
`;

interface ResourceCardCompaniesKyuProps {
  highlight?: boolean;
}

export const ResourceCardCompaniesKyu = styled.span<ResourceCardCompaniesKyuProps>`
  font-weight: ${({ highlight }) => (highlight ? 700 : 500)};
  font-size: 14px;
  line-height: normal;
  color: var(--dark-theme-text-primary, #fff);
`;

export const ResourceCardCompaniesContainer = styled.div`
  max-width: 50%;
`;
