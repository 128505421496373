import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'navbar',
  initialState: {
    isNavBarOpen: true,
    isNavMenuOpen: false,
    focusSearch: false,
    isDrawerOpen: false,
    isSpotlightOpen: false,
    isUserDropdownOpen: false,
    isFeedbackDrawerOpen: false,
  },
  reducers: {
    setIsNavBarOpen: (state, action: { payload: boolean }) => ({
      ...state,
      isNavBarOpen: action.payload,
    }),
    setFocusSearch: (state, action: { payload: boolean }) => ({
      ...state,
      focusSearch: action.payload,
    }),
    setIsDrawerOpen: (state, action: { payload: boolean }) => ({
      ...state,
      isDrawerOpen: action.payload,
    }),
    setIsNavbarMenuOpen: (state, action: { payload: boolean }) => ({
      ...state,
      isNavMenuOpen: action.payload,
    }),
    setIsSpotlightOpen: (state, action: { payload: boolean }) => ({
      ...state,
      isSpotlightOpen: action.payload,
    }),
    setIsUserDropdownOpen: (state, action: { payload: boolean }) => ({
      ...state,
      isUserDropdownOpen: action.payload,
    }),
    setIsFeedbackDrawerOpen: (state, action: { payload: boolean }) => ({
      ...state,
      isFeedbackDrawerOpen: action.payload,
    }),
  },
});

export const {
  setIsNavBarOpen,
  setFocusSearch,
  setIsDrawerOpen,
  setIsNavbarMenuOpen,
  setIsSpotlightOpen,
  setIsUserDropdownOpen,
  setIsFeedbackDrawerOpen,
} = slice.actions;

export const isNavBarOpenSelector = (state) => state.navbar.isNavBarOpen;
export const focusSearchSelector = (state) => state.navbar.focusSearch;
export const isDrawerOpenSelector = (state) => state.navbar.isDrawerOpen;
export const isNavMenuOpenSelector = (state) => state.navbar.isNavMenuOpen;
export const isSpotlightOpenSelector = (state) => state.navbar.isSpotlightOpen;
export const isUserDropdownOpenSelector = (state) => state.navbar.isUserDropdownOpen;
export const isFeedbackDrawerOpenSelector = (state) => state.navbar.isFeedbackDrawerOpen;

export default slice.reducer;
