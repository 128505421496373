import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const NavbarSpotlightWrapper = styled.div`
  padding: 24px 0px;

  border-radius: 0px 0px 48px 48px;
  background: ${({ theme }) => theme.backgroundMenuColor};

  &:before {
    content: '';

    width: 100%;
    height: 100%;

    position: absolute;
    top: 0px;
    left: 0;

    backdrop-filter: blur(24px);
  }

  & > :first-child {
    position: relative;

    padding: 0px 24px;
  }

  & div {
    box-shadow: none;
  }

  ${BREAKPOINTS.tablet} {
    border-radius: 0px;
  }

  user-select: none;
  position: relative;
`;

//CTA
export const NavbarSpotlightCTAWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 8px 16px;

  gap: 8px;

  border-radius: 8px;
  &:hover {
    background: rgba(23, 28, 51, 0.1);
  }

  cursor: pointer;
  user-select: none;
`;

export const NavbarSpotlightCTATitle = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: normal;

  color: var(--color-text);
`;

export const NavbarSpotlightCTAIconContainer = styled.div`
  position: relative;
`;

export const NavbarSpotlightCTAIconBeacon = styled.div`
  width: 6px;
  height: 6px;

  position: absolute;
  top: -2px;
  right: -2px;

  border-radius: 100%;
  background: rgba(235, 61, 61, 1);
`;
