import styled from 'styled-components';

interface CompaniesArrayWrapperProps {
  alignCompanies?: 'flex-start' | 'center' | 'flex-end';
}

export const CompaniesArrayWrapper = styled.div<CompaniesArrayWrapperProps>`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: ${({ alignCompanies }) => alignCompanies || 'flex-end'};
`;

interface CompaniesArrayContainerProps {
  companyLogoSize: number;
}

export const CompaniesArrayContainer = styled.div<CompaniesArrayContainerProps>`
  width: fit-content;

  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;

  & > :not(:first-child) {
    margin-right: -${({ companyLogoSize }) => companyLogoSize / 4}px;
  }

  & > .companies-array-more-counter {
    height: ${({ companyLogoSize }) => companyLogoSize}px;
    margin-left: 2px;
  }

  & > * {
    pointer-events: auto;
    position: relative;
  }

  & > div:not(.companies-array-more-counter):before {
    content: '';
    position: absolute;
    background-color: #ffffff;
    border-radius: 100%;
    width: ${({ companyLogoSize }) => companyLogoSize}px;
    height: ${({ companyLogoSize }) => companyLogoSize}px;
    top: 0px;
    left: 0px;
    z-index: -1;
  }

  &:hover img {
    opacity: 0.5;
  }

  &:hover > *:hover > img {
    opacity: 1;
  }
`;

export const CompaniesArrayMoreCounter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0px 8px;
  border-radius: 1000px;

  background: rgba(84, 120, 161, 0.3);
  border: 2px solid rgba(84, 120, 161, 1);
`;

export const CompaniesArrayMoreCounterText = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;

  color: rgba(84, 120, 161, 1);
`;
