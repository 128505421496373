import { motion } from 'framer-motion';
import styled from 'styled-components';
import {
  slideFromBottomAnimation,
  slideFromRightAnimation,
  slideFromTopAnimation,
  slideToBottomAnimation,
  slideToRightAnimation,
  slideToTopAnimation,
  appearAnimation,
} from 'styles/animations';
import { BREAKPOINTS } from 'styles/media';

const colorsByMode = {
  dark: '#171C33',
  light: '#fff',
};

const backgroundsByMode = {
  dark: { primary: '#EFEFEF', secondary: '#FDFDFC' },
  light: { primary: '#757886', secondary: '#ADAFB8' },
};

interface ModeProps {
  mode?: 'dark' | 'light';
  isOpen?: boolean;
}

export const ToggleNavBarTab = styled.button<ModeProps>`
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 0px 0px 4px 4px;
  cursor: pointer;
  display: flex;
  height: 20px;
  justify-content: space-between;
  left: calc(50% - 35px);
  padding: 2px 8px;
  position: fixed;
  top: 0;
  width: 70px;
  z-index: 999991;
  ${({ mode }) => `
  > svg {
    height: 16px;
    width: 16px;
    > path {
      fill: ${colorsByMode[mode]};
    }
  }
  > span {
    color: ${colorsByMode[mode]};
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;
  }
  `}
  ${BREAKPOINTS.tablet} {
    display: none;
  }
`;

export const FilterButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 34px;
  top: 115px;
  z-index: 999;
  > button {
    align-items: center;
    background: #ffc229;
    border-radius: 100%;
    display: flex;
    height: 40px;
    justify-content: center;
    margin-bottom: 16px;
    width: 40px;
    &:hover {
      background: #9ccaff;
    }
    > svg {
      width: 22px;
      height: 22px;
      path {
        stroke: #171c33;
      }
    }
  }
`;

interface NavBarWrapperProps extends ModeProps {
  triggerClose: boolean;
  isMenuOpen?: boolean;
}

export const NavBarWrapper = styled.nav<NavBarWrapperProps>`
  width: 100%;
  height: 84px;

  position: fixed;
  left: 0;
  top: 0;

  display: grid;
  grid-template-columns: minmax(157px, 1fr) minmax(min-content, 1440px) minmax(auto, 1fr);
  align-items: center;
  justify-content: space-between;

  &:before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(200px);
  }

  background: ${({ theme }) => theme.backgroundMenuColor};
  box-shadow: 0px 0px 20px 0px rgba(23, 28, 51, 0.25);

  ${({ triggerClose }) => (triggerClose ? slideToTopAnimation : slideFromTopAnimation)}

  ${BREAKPOINTS.tablet} {
    height: 56px;
    bottom: 0;
    top: auto;
    display: flex;
  }

  z-index: 99999;
`;

export const NavBarLogo = styled.button<ModeProps>`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 157px;
  > span,
  h1 {
    font-size: 16px;
    line-height: 21px;
  }
  > h1 {
    font-weight: 700;
    margin-left: 5px;
  }
  &:hover {
    background: rgba(255, 255, 255, 0.75);
  }
  &:after {
    background: #eb3d3d;
    border-radius: 0px 0px 4px 4px;
    color: #fff;
    content: 'BETA';
    font-size: 12px;
    font-weight: bold;
    left: calc(50% - 22px);
    padding: 2px 8px;
    position: absolute;
    top: 0;
  }
  svg {
    margin-right: 10px;
  }

  ${BREAKPOINTS.tablet} {
    width: 81px;
  }
`;

export const NavBarUserDropdownContainer = styled.div`
  height: 100%;
  position: relative;
`;

export const NavBarProgressBarContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-end;

  & > div {
    width: fit-content;
    ${BREAKPOINTS.mobile} {
      width: 100%;
    }
  }

  padding: 0px 10px;
`;

interface NavSectionsWrapperProps {
  isOpen: boolean;
}

export const NavSectionsWrapper = styled.div<NavSectionsWrapperProps>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  width: 100%;
  height: 100%;

  ${BREAKPOINTS.tablet} {
    display: none;
  }

  & > :last-child {
    z-index: 2;
  }

  position: relative;
`;

export const NavSectionsSearchAndProgressContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  & > :not(:last-child) {
    margin-right: 24px;
  }
`;

export const NavSectionsButtonsContainer = styled.div`
  height: 100%;
  align-items: center;
  display: flex;
  z-index: 2;

  position: relative;
`;

export const NavButtonWrapper = styled.div`
  height: 100%;
  border-bottom: ${({ active, theme }) => (active ? '2px solid ' + theme.color : 'none')};
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;

  position: relative;
`;

export const NavButton = styled.button<ModeProps>`
  align-items: center;
  border-radius: 40px;
  cursor: pointer;
  display: flex;
  padding: 10px 16px;

  svg {
    margin-right: 10px;
    z-index: 1;
  }

  span {
    color: ${({ theme }) => theme.color};
    font-size: 16px;
    white-space: nowrap;
    z-index: 1;
  }

  background: ${({ theme, isOpen }) => isOpen && theme.navButtonBackground};

  &:hover {
    background: ${({ theme }) => theme.navButtonBackground};
  }

  ${BREAKPOINTS.tablet} {
    padding-left: 0;
  }

  z-index: 2;
`;

export const SearchWrapper = styled.div<ModeProps>`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  > button {
    align-items: center;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(40px);
    border-radius: 200px;
    display: block;
    display: flex;
    height: 44px;
    justify-content: center;
    margin: 26px 0px;
    text-align: left;
    padding: 12px 16px;
    &:hover {
      background: rgba(255, 255, 255, 0.75);
    }
    > svg {
      margin-left: 10px;
      width: 23px;
      height: 23px;
      path {
        stroke: #171c33;
      }
    }
  }
  ${BREAKPOINTS.tablet} {
    display: none;
  }
`;

interface SearchWrapperProps {
  triggerClose: boolean;
}

export const SearchInputWrapper = styled.div<SearchWrapperProps>`
  width: 100%;
  ${slideFromRightAnimation}
  ${({ triggerClose }) => (triggerClose ? slideToRightAnimation : '')}
  > div {
    margin-top: 0;
  }
  ${BREAKPOINTS.tablet} {
    display: none;
  }
`;

export const UserDropdownWrapper = styled.div`
  height: 100%;
  position: relative;
  width: fit-content;
  ${BREAKPOINTS.tablet} {
    display: none;
  }
`;

export const UserDropdownInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1;
`;

export const UserDropdownName = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--color-text);
`;

export const UserDropdownCompany = styled.span`
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 1.4px;
  text-transform: uppercase;
`;

export const UserDropdownAvatarDropdownContainer = styled.div`
  position: absolute;
  top: -17px;
  left: -23px;
  pointer-events: auto;
  z-index: 2;
`;

interface UserDropdownButtonProps extends ModeProps {
  isOpen?: boolean;
  isAvatarDropdownOpen?: boolean;
}

export const UserDropdownButton = styled.button<UserDropdownButtonProps>`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  width: fit-content;
  min-width: 195px;
  padding: 0px 24px;

  &:before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(40px);
  }

  > div {
    ${({ isAvatarDropdownOpen }) =>
      !isAvatarDropdownOpen &&
      `
      cursor: pointer;
    `}
  }

  &:hover {
    background: rgba(255, 255, 255, 0.75);
  }

  .arrow-icon {
    transition: all 0.3s ease;
    transform: rotate(${({ isOpen }) => (isOpen ? '180deg' : '0')});
  }

  & > :not(:last-child) {
    margin-right: 8px;
  }

  position: relative;
  ${({ isAvatarDropdownOpen }) => isAvatarDropdownOpen && `pointer-events: none;`}
`;

interface UserDropdownPersonAvatarContainerProps {
  showBeacon?: boolean;
}

export const UserDropdownPersonAvatarContainer = styled.div<UserDropdownPersonAvatarContainerProps>`
  ${({ showBeacon }) =>
    showBeacon &&
    `
    & > .person-avatar {
      border: 2px solid var(--color-data-skill-background);
      box-shadow: 0px 0px 100px 0px var(--color-data-skill-background);
      position: relative;
    }
  `}
`;

export const UserDropdownPeronAvatarBeaconContainer = styled.div`
  position: absolute;
  top: -8px;
  right: -5px;
`;

export const UserDropdownIconContainer = styled.div`
  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UserDropdownMenu = styled.div<ModeProps>`
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(20px);
  border-radius: 0px 0px 24px 24px;
  display: flex;
  flex-direction: column;
  padding: 16px;

  position: absolute;
  left: 0px;
  top: 84px;

  z-index: 99999;
  > button {
    margin-right: 0;
    width: 100%;
  }
`;

export const SignOutButton = styled.button`
  color: ${({ theme }) => theme.signOutColor};
  display: flex;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 16px;
`;

interface MobileMenuWrapperProps {
  triggerClose: boolean;
}

export const MobileMenuWrapper = styled.div<MobileMenuWrapperProps>`
  ${slideFromBottomAnimation}
  width: 100%;
  position: fixed;
  top: 110px;
  bottom: 56px;
  z-index: 99999999999999;
  overflow-y: scroll;
  ${({ triggerClose }) => (triggerClose ? slideToBottomAnimation : '')}
`;

export const MobileMenuButton = styled.button`
  background: rgba(255, 255, 255, 0.4);
  height: 100%;
  width: 66px;
  z-index: 1;
  &:hover {
    background: rgba(255, 255, 255, 0.75);
  }
`;

export const MobileMenuOverlay = styled.div`
  align-items: flex-end;
  bottom: 56px;
  display: flex;
  left: 0;
  position: absolute;
  width: 100%;
`;

interface MobileMenuContainerProps extends ModeProps {
  triggerClose?: boolean;
}

export const MobileMenuContainer = styled.div<MobileMenuContainerProps>`
  ${slideFromBottomAnimation}
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  ${({ triggerClose }) => (triggerClose ? slideToBottomAnimation : '')}
  > div {
    display: flex;
    padding: 32px;
    width: 100%;
  }
`;

export const MobileMenuTopContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color};
  flex-direction: column;
  position: relative;
  > div.user-wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    width: 100%;
    > div.avatar-container {
      align-items: center;
      display: flex;
      > div.user-data {
        display: flex;
        flex-direction: column;
        > span,
        b {
          font-size: 14px;
          line-height: 18px;
        }
        > span {
          color: rgba(255, 255, 255, 0.5);
        }
        > b {
          color: ${({ theme }) => theme.color};
          font-weight: 700;
        }
      }
    }
  }
`;

export const MobileMenuTopLinksContainer = styled.div`
  padding-left: 20px;
  padding-bottom: 25px;
  svg {
    width: 20px;
    height: 20px;
  }
`;

export const MobileMenuBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 0px;
  border-bottom: 1px solid ${({ theme }) => theme.color};
`;

export const MobileMenuSearchContainer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MobileMenuSearchButton = styled.button`
  display: flex;
  width: 100%;
  color: $({(theme)} => theme.color);
  background: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  padding: 15px 0px;
  margin-top: 5px;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 10px;
  }
`;

export const MobileMenuLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
`;

export const MobileMenuLink = styled.a`
  display: flex;
  justify-content: space-between;
  padding: 15px 20px 15px 0px;
`;

export const MobileMenuArrow = styled.span`
  color: ${({ theme }) => theme.arrowColor};
  font-size: 18px;
`;

export const MobileMenuLinkTitle = styled.span`
  color: ${({ theme }) => theme.color};
  font-size: 18px;
`;

export const MobileNavButton = styled(NavButton)`
  justify-content: space-between;
  border-radius: 10px;
  height: 71px;
  margin-right: 8px;
  min-width: 50px;
  padding: 8px;
  > svg {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

//BOXES

export const NavBoxDivider = styled.div`
  width: 100%;
  height: 1px;

  background: ${({ theme }) => theme.divider};
  border-radius: 2px;

  margin: 8px 0px 24px 0px;
`;

export const NavBoxesContainer = styled.div`
  position: fixed;
  z-index: 99999;
  top: 90px;
  ${BREAKPOINTS.tablet} {
    display: none;
  }
`;

interface NavBoxProps {
  left?: string;
  padding?: string;
  display?: string;
}

export const NavBox = styled.div<NavBoxProps>`
  background: rgba(255, 255, 255, 0.4);
  width: 300px;
  border-radius: 30px;
  display: ${({ display }) => display};
  flex-direction: column;
  position: fixed;
  left: ${({ left }) => left};
  padding: ${({ padding }) => padding || '0px'};
  backdrop-filter: blur(40px);
`;
export const NavBoxInfoContainer = styled.div``;

export const NavBoxTitle = styled.p`
  color: ${({ theme }) => theme.color};
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 5px;
`;

export const NavBoxDescription = styled.p`
  color: ${({ theme }) => theme.color};
  font-size: 12px;
  line-height: 18px;
`;

export const NavBoxItemsContainer = styled.div`
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  max-height: 600px;
  ${({ scroll }) => (scroll ? 'overflow-y: scroll;' : '')}

  &::-webkit-scrollbar-track {
    visibility: none;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(23, 28, 51, 0.5);
    height: 30px;
  }
`;

interface NavBoxItemWrapperProps {
  active: boolean;
}

export const NavBoxItemWrapper = styled.div<NavBoxItemWrapperProps>`
  border-left: ${({ active, theme }) => (active ? '2px solid ' + theme.color : 'none')};
`;
export const NavBoxItem = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 8px 10px 8px 10px;
  border-radius: 40px;
  &:hover {
    background: ${({ theme }) => theme.hoverItemColor};
  }
`;

export const NavBoxLink = styled.span`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-color: ${({ theme }) => theme.color};
  margin-left: ${({ left }) => left};
  margin-right: 5px;
`;

export const NavBoxItemIcon = styled.div`
  color: ${({ theme }) => theme.color};
  margin-right: 8px;
  margin-top: 4px;
  svg {
    width: 18px;
    height: 18px;
  }
`;
export const NavBoxItemTitle = styled.span`
  font-color: ${({ theme }) => theme.color};
  color: ${({ theme }) => theme.color};
  font-size: 16px;
`;
export const NavBoxItemArrow = styled.span`
  color: ${({ theme }) => theme.arrowColor};
  margin-top: 3px;
  margin-left: 15px;
  font-size: 20px;
`;

//Mobile Levels

export const MobileMenuRootContainer = styled.div`
  flex-direction: column;
  z-index: 0;
  height: 570px;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(24px);
`;

export const MobileMenuRootCompanyName = styled.span`
  color: ${({ theme }) => theme.companyNameColor}!important;
`;

export const MobileMenuLevel = styled.div`
  flex-direction: column;
  padding: 37px;
  height: 570px;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(24px);
`;

export const MobileMenuSearchBoxContainer = styled.div`
  ${appearAnimation}
  position: fixed;
  display: flex;
  text-align: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  z-index: 99999;
  top: 0px;
  background: rgba(23, 28, 51, 0.7);
  backdrop-filter: blur(24px);
  ${({ triggerSearchBox }) => (triggerSearchBox ? appearAnimation : '')}
`;

export const MobileMenuSearchBoxClose = styled.span`
  position: absolute;
  right: 43px;
  top: 40px;
  svg {
    width: 15px;
    height: 15px;
  }
`;

export const MobileMenuSearchBoxTitle = styled.h1`
  color: #fff;
  font-size: 24px;
  margin-top: 100px;
  line-height: 35px;
`;

export const MobileMenuSearchBoxBar = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

export const MobileMenuFirstLevelContainer = styled(MobileMenuLevel)`
  z-index: 5;
`;
export const MobileMenuSecondLevelContainer = styled(MobileMenuLevel)`
  z-index: 10;
`;

export const MobileMenuGoBackLink = styled.span`
  color: ${({ theme }) => theme.arrowColor};
  font-size: 16px;
  display: flex;
  align-items: center;
`;

export const MobileMenuGoBackText = styled.span`
  color: ${({ theme }) => theme.arrowColor};
  margin-left: 5px;
`;
export const MobileMenuFirstLevelContent = styled.div`
  display: ${({ display }) => display};
`;

export const MobileMenuFirstLevelInfo = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: ${({ theme }) => theme.color};
  margin-bottom: 25px;
`;

export const MobileMenuFirstLevelTitle = styled.span`
  font-size: 16px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.color};
`;

export const MobileMenuFirstLevelDescription = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.color};
`;

export const MobileMenuFirstLevelLinks = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MobileMenuFirstLevelLink = styled.a`
  display: ${({ display }) => display};
  align-items: center;
  margin-bottom: 25px;
  svg {
    height: 20px;
    width: 20px;
  }
`;

export const MobileMenuFirstLevelLinkTitle = styled.span`
  color: ${({ theme }) => theme.color};
  margin-left: 8px;
  font-size: 18px;
`;

export const MobileMenuSecondLevelContent = styled.div`
  padding-left: 10px;
`;
export const MobileMenuCompanies = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
  height: 500px;
  margin-top: 20px;
`;

export const MobileMenuCompanyLink = styled.a`
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : '0px')};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;
  padding: 10px 0px;
`;

export const MobileMenuCompanyIcon = styled.span`
  svg {
    width: 19px;
    height: 19px;
  }
`;
export const MobileMenuCompanyName = styled.span`
  color: ${({ theme }) => theme.color};
  font-size: 18px;
  margin-left: 8px;
`;

// OPEN NAV SECTION
export const NavSectionsOpenWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 400px;
  width: 100%;

  padding: 24px;

  border-radius: 0px 0px 48px 48px;
  backdrop-filter: blur(200px);

  background: ${({ theme }) => theme.backgroundMenuColor};
  box-shadow: rgba(23, 28, 51, 0.25) 0px 0px 20px 0px;

  & > :not(:last-child) {
    margin-right: 32px;
  }
`;

export const NavSectionsOpenContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const NavSectionsOpenTitle = styled.h5`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  letter-spacing: 1.6px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.sectionTitleColor};
`;

export const NavSectionsOpenExploreWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const NavSectionsOpenToolsWrapper = styled.div`
  width: 142px;

  display: flex;
  flex-direction: column;

  background: rgba(255, 255, 255, 0.3);

  padding: 8px;
  border-radius: 8px;

  & > div {
    background: none;

    &:hover {
      background: rgba(255, 255, 255, 0.75);
    }
  }

  & > :last-child:before {
    content: '';

    width: 100%;
    height: 1px;

    position: absolute;
    top: 0px;
    left: 0px;

    background: rgba(255, 255, 255, 0.5);
  }

  & > :first-child:hover {
    & + div:before {
      display: none;
    }
  }

  & > :last-child:hover {
    &:before {
      display: none;
    }
  }
`;

export const NavSectionsOpenTilesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
`;

export const NavSectionsOpenTilesContainer = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
`;

export const NavSectionsOpenWaysToGetInvolvedWrapper = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const NavSectionsOpenWaysToGetInvolvedTitle = styled.h6`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.waysToGetInvolvedTitle};
`;

export const NavSectionsOpenWaysToGetInvolvedContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }

  width: 264px;
`;

interface NavSectionsOpenWaysToGetInvolvedButtonProps {
  stroke?: boolean;
}

export const NavSectionsOpenWaysToGetInvolvedButton = styled.button<NavSectionsOpenWaysToGetInvolvedButtonProps>`
  width: fit-content;
  max-height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  padding: 16px 32px 16px 24px;
  border-radius: 100px;
  border: 2px solid ${({ theme }) => theme.color};

  transition: all 200ms ease-in-out;

  & > svg > path {
    ${({ theme, stroke = false }) => (stroke ? `stroke: ${theme.color};` : `fill: ${theme.color}`)}
  }

  &:hover {
    background: var(--color-data-client-background-secondary);
  }
`;

export const NavSectionsOpenWaysToGetInvolvedButtonLabel = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  text-align: center;

  color: ${({ theme }) => theme.color};
`;

export const NavSectionsOpenWaysToGetInvolvedLink = styled.a`
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.waysToGetInvolvedLink};

  cursor: pointer;

  transition: all 200ms ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.waysToGetInvolvedLinkHover};
  }
  text-decoration: none;
`;

// NAV SECTIONS ONBOARDING
export const NavSectionsOnboardingWrapper = styled.div`
  position: absolute;

  left: -7.5px;

  height: 56px;
  width: calc(100% + 15px);

  border-radius: 1000px;
  border: 2px solid var(--data-skill-background, #ffa366);
  background: rgba(255, 255, 255, 0.01);
  box-shadow: 0px 0px 100px 0px #ffa366;

  pointer-events: none;
`;

export const NavSectionsOnboardingCalloutWrapper = styled.div`
  position: absolute;

  top: calc(100% + 12px);
  left: 24px;

  width: 295px;

  border-radius: 16px;
  border: 2px solid var(--data-skill-background, #ffa366);
  background: var(--data-skill-background-secondary, rgba(235, 130, 61, 0.3));
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  &:before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(18px);
    border-radius: 16px;
  }
`;

export const NavSectionsOnboardingHintContainer = styled(motion.div)`
  width: 100%;
  height: 90px;

  position: absolute;
  top: 0px;
  left: 0px;

  border-radius: 0px 0px 48px 48px;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(24px);
`;

export const NavSectionsOnboardingCalloutContainer = styled.div`
  width: 100%;
  height: 100%;

  padding: 16px;

  position: relative;
`;

export const NavSectionsOnboardingCalloutText = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
`;

export const NavSectionsOnboardingCalloutCloseContainer = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;

  width: 24px;
  height: 24px;

  border-radius: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;
