import { ApiResponse } from 'apisauce';
import { IResource } from 'app/components/Resources/ResourceCard';
import { IResourceIndexParams, IResourceIndexResponse } from 'app/components/Resources/interfaces';

import { http } from './http';

export const getResources = (
  params: IResourceIndexParams = {
    per_page: 9,
  }
) => {
  return http.get<IResourceIndexResponse>(`resources`, params);
};

export const getResource = (slug: string): Promise<ApiResponse<IResource, IResource>> => {
  return http.get<IResource>(`resources/${slug}`);
};
