import { keyframes, css } from 'styled-components';

const slideFromRightKeyframes = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
`;

export const slideFromRightAnimation = () => css`
  animation: 0.45s ${slideFromRightKeyframes} forwards;
  -webkit-animation: 0.45s ${slideFromRightKeyframes} forwards;
  -moz-animation: 0.45s ${slideFromRightKeyframes} forwards;
  -o-animation: 0.45s ${slideFromRightKeyframes} forwards;
`;

const slideToRightKeyframes = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(110%);
  }
`;

export const slideToRightAnimation = () => css`
  animation: 0.45s ${slideToRightKeyframes} forwards;
  -webkit-animation: 0.45s ${slideToRightKeyframes} forwards;
  -moz-animation: 0.45s ${slideToRightKeyframes} forwards;
  -o-animation: 0.45s ${slideToRightKeyframes} forwards;
`;

const pulseKeyframes = (initial, final) => keyframes`
  0% {
    background:${initial};
  }
  50% {
    background:${final};
  }
  100% {
    background:${initial};
  }
`;

const slideFromBottomKeyframes = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
`;

export const slideFromBottomAnimation = () => css`
  animation: 0.45s ${slideFromBottomKeyframes} forwards;
  -webkit-animation: 0.45s ${slideFromBottomKeyframes} forwards;
  -moz-animation: 0.45s ${slideFromBottomKeyframes} forwards;
  -o-animation: 0.45s ${slideFromBottomKeyframes} forwards;
`;

const slideToBottomKeyframes = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(110%);
  }
`;

export const slideToBottomAnimation = () => css`
  animation: 0.45s ${slideToBottomKeyframes} forwards;
  -webkit-animation: 0.45s ${slideToBottomKeyframes} forwards;
  -moz-animation: 0.45s ${slideToBottomKeyframes} forwards;
  -o-animation: 0.45s ${slideToBottomKeyframes} forwards;
`;

const slideFromTopKeyframes = keyframes`
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
`;

export const slideFromTopAnimation = () => css`
  animation: 0.45s ${slideFromTopKeyframes} forwards;
  -webkit-animation: 0.45s ${slideFromTopKeyframes} forwards;
  -moz-animation: 0.45s ${slideFromTopKeyframes} forwards;
  -o-animation: 0.45s ${slideFromTopKeyframes} forwards;
`;

const slideToTopKeyframes = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-110%);
  }
`;

export const slideToTopAnimation = () => css`
  animation: 0.45s ${slideToTopKeyframes} forwards;
  -webkit-animation: 0.45s ${slideToTopKeyframes} forwards;
  -moz-animation: 0.45s ${slideToTopKeyframes} forwards;
  -o-animation: 0.45s ${slideToTopKeyframes} forwards;
`;

export const pulseAnimation = (initial = '#f7f7f7', final = '#f0f0f0') => css`
  animation: 2s ${pulseKeyframes(initial, final)} infinite ease-in-out;
  -webkit-animation: 2s ${pulseKeyframes(initial, final)} infinite ease-in-out;
  -moz-animation: 2s ${pulseKeyframes(initial, final)} infinite ease-in-out;
  -o-animation: 2s ${pulseKeyframes(initial, final)} infinite ease-in-out;
`;

const appearKeyframes = (opacity: string) => keyframes`
  0% {
    opacity: 0.05;
  }
  100% {
    opacity: ${opacity};
  }
`;

export const appearAnimation = (opacity: string) => css`
  animation: 0.5s ${appearKeyframes(opacity)} forwards;
  -webkit-animation: 0.5s ${appearKeyframes(opacity)} forwards;
  -moz-animation: 0.5s ${appearKeyframes(opacity)} forwards;
  -o-animation: 0.5s ${appearKeyframes(opacity)} forwards;
`;

const disappearKeyframes = (opacity: string) => keyframes`
  0% {
    opacity: ${opacity};
  }
  100% {
    opacity: 0;
  }
`;

export const disappearAnimation = (opacity: string) => css`
  animation: 0.5s ${disappearKeyframes(opacity)} forwards;
  -webkit-animation: 0.5s ${disappearKeyframes(opacity)} forwards;
  -moz-animation: 0.5s ${disappearKeyframes(opacity)} forwards;
  -o-animation: 0.5s ${disappearKeyframes(opacity)} forwards;
`;

const slideFromBottomLeftKeyframes = keyframes`
  0% {
    opacity: 0.25;
    transform: translate(-100%, 100%) scale(0.2);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0) scale(1);
  }
`;

export const slideFromBottomLeftAnimation = () => css`
  animation: 0.35s ${slideFromBottomLeftKeyframes} forwards;
  -webkit-animation: 0.35s ${slideFromBottomLeftKeyframes} forwards;
  -moz-animation: 0.35s ${slideFromBottomLeftKeyframes} forwards;
  -o-animation: 0.35s ${slideFromBottomLeftKeyframes} forwards;
`;

const slideToBottomLeftKeyframes = keyframes`
  0% {
    transform: translate(0, 0) scale(1);
  }
  100% {
    opacity: 0.1;
    transform: translate(-100%, 100%) scale(0.2);
  }
`;

export const slideToBottomLeftAnimation = () => css`
  animation: 0.35s ${slideToBottomLeftKeyframes} forwards;
  -webkit-animation: 0.35s ${slideToBottomLeftKeyframes} forwards;
  -moz-animation: 0.35s ${slideToBottomLeftKeyframes} forwards;
  -o-animation: 0.35s ${slideToBottomLeftKeyframes} forwards;
`;

const slideFromBottomRightKeyframes = keyframes`
  0% {
    opacity: 0.25;
    transform: translate(100%, 100%) scale(0.2);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0) scale(1);
  }
`;

export const slideFromBottomRightAnimation = () => css`
  animation: 0.35s ${slideFromBottomRightKeyframes} forwards;
  -webkit-animation: 0.35s ${slideFromBottomRightKeyframes} forwards;
  -moz-animation: 0.35s ${slideFromBottomRightKeyframes} forwards;
  -o-animation: 0.35s ${slideFromBottomRightKeyframes} forwards;
`;

const slideToBottomRightKeyframes = keyframes`
  0% {
    transform: translate(0, 0) scale(1);
  }
  100% {
    transform: translate(100%, 100%) scale(0.2);
  }
`;

export const slideToBottomRightAnimation = () => css`
  animation: 0.35s ${slideToBottomRightKeyframes} forwards;
  -webkit-animation: 0.35s ${slideToBottomRightKeyframes} forwards;
  -moz-animation: 0.35s ${slideToBottomRightKeyframes} forwards;
  -o-animation: 0.35s ${slideToBottomRightKeyframes} forwards;
`;

export const buttonKeyframes = keyframes`
  0% {
    opacity: 0.1;
    transform: translate(-2%, 2%) rotate(0deg);
  }
  5% {
    opacity: 0.75;
    transform: translate(-2%, 2%) rotate(0deg);
  }
  50% {
    opacity: 1;
    transform: translate(5%, -5%) rotate(180deg);
  }
  100% {
    opacity: 0;
    transform: translate(-2%, 2%) rotate(360deg);
  }
`;

export const buttonAnimation = () => css`
  animation: 5s ${buttonKeyframes} linear forwards;
  -webkit-animation: 5s ${buttonKeyframes} linear forwards;
  -moz-animation: 5s ${buttonKeyframes} linear forwards;
  -o-animation: 5s ${buttonKeyframes} linear forwards;
`;

export const slideToRightAndLeftCardKeyframes = keyframes`
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateY(-10%) translateX(50%) rotate(15deg);
  }
  75% {
    z-index: 0;
  }
  100% {
    transform: translateY(0) translateX(0) rotate(-5deg);
    opacity: 0.6;
    z-index: 0;
  }
`;

export const slideToRightAndLeftCardAnimation = () => css`
  animation: 0.75s ${slideToRightAndLeftCardKeyframes} forwards;
  -webkit-animation: 0.75s ${slideToRightAndLeftCardKeyframes} forwards;
  -moz-animation: 0.75s ${slideToRightAndLeftCardKeyframes} forwards;
  -o-animation: 0.75s ${slideToRightAndLeftCardKeyframes} forwards;
`;

export const slideToLeftAndRightCardKeyframes = keyframes`
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateY(-10%) translateX(-50%) rotate(-15deg);
  }
  75% {
    z-index: 0;
  }
  100% {
    transform: translateY(0) translateX(0) rotate(-5deg);
    opacity: 0.6;
    z-index: 0;
  }
`;

export const slideToLeftAndRightCardAnimation = () => css`
  animation: 0.75s ${slideToLeftAndRightCardKeyframes} forwards;
  -webkit-animation: 0.75s ${slideToLeftAndRightCardKeyframes} forwards;
  -moz-animation: 0.75s ${slideToLeftAndRightCardKeyframes} forwards;
  -o-animation: 0.75s ${slideToLeftAndRightCardKeyframes} forwards;
`;

export const slideToRightAndLeftExtraCardKeyframes = keyframes`
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateY(-10%) translateX(-50%) rotate(15deg);
  }
  75% {
    z-index: 2;
  }
  100% {
    transform: translateY(0) translateX(0);
    z-index: 2;
    opacity: 1;
  }
`;

export const slideToRightAndLeftExtraCardAnimation = () => css`
  animation: 0.75s ${slideToRightAndLeftExtraCardKeyframes} forwards;
  -webkit-animation: 0.75s ${slideToRightAndLeftExtraCardKeyframes} forwards;
  -moz-animation: 0.75s ${slideToRightAndLeftExtraCardKeyframes} forwards;
  -o-animation: 0.75s ${slideToRightAndLeftExtraCardKeyframes} forwards;
`;

export const slideToLeftAndRightExtraCardKeyframes = keyframes`
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateY(-10%) translateX(50%) rotate(-15deg);
  }
  75% {
    z-index: 2;
  }
  100% {
    transform: translateY(0) translateX(0);
    z-index: 2;
    opacity: 1;
  }
`;

export const slideToLeftAndRightExtraCardAnimation = () => css`
  animation: 0.75s ${slideToLeftAndRightExtraCardKeyframes} forwards;
  -webkit-animation: 0.75s ${slideToLeftAndRightExtraCardKeyframes} forwards;
  -moz-animation: 0.75s ${slideToLeftAndRightExtraCardKeyframes} forwards;
  -o-animation: 0.75s ${slideToLeftAndRightExtraCardKeyframes} forwards;
`;

export const rotateKeyframes = (degrees: number, goUp: boolean) => keyframes`
  0% {
    transform: rotate(0deg);
  }
  ${
    goUp
      ? `
    50% {
      transform: translateY(-10%);
    }
  `
      : ``
  }
  
  100% {
    transform: translateY(0) rotate(${degrees}deg);
  }
`;

export const rotateAnimation = (degrees: number, duration: number = 0.5, goUp = true) => css`
  animation: ${duration}s ${rotateKeyframes(degrees, goUp)} forwards;
  -webkit-animation: ${duration}s ${rotateKeyframes(degrees, goUp)} forwards;
  -moz-animation: ${duration}s ${rotateKeyframes(degrees, goUp)} forwards;
  -o-animation: ${duration}s ${rotateKeyframes(degrees, goUp)} forwards;
`;

export const unblurKeyframes = () => keyframes`
  from {
    backdrop-filter: blur(20px);
  }

  to {
    backdrop-filter: blur(0);
  }
`;

export const unblurAnimation = () => css`
  animation: 0.3s ${unblurKeyframes} linear forwards;
  -webkit-animation: 0.3s ${unblurKeyframes} linear forwards;
  -moz-animation: 0.3s ${unblurKeyframes} linear forwards;
  -o-animation: 0.3s ${unblurKeyframes} linear forwards;
`;

export const infiniteRotationKeyframes = () => keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const infiniteRotationAnimation = (duration = '5s', type = 'linear') => css`
  animation: ${infiniteRotationKeyframes} ${duration} ${type} infinite;
  -webkit-animation: ${infiniteRotationKeyframes} ${duration} ${type} infinite;
  -moz-animation: ${infiniteRotationKeyframes} ${duration} ${type} infinite;
  -o-animation: ${infiniteRotationKeyframes} ${duration} ${type} infinite;
`;

export const expandKeyframes = ({ startingWidth = '0px', finalWidth = '100%' }) => keyframes`
  0% {
    width: ${startingWidth};
  }
  100% {
    width: ${finalWidth};
  }
`;

export const expandAnimation = ({
  startingWidth = '50%',
  finalWidth = '100%',
  duration = '200ms',
  type = 'ease-in-out',
}) => css`
  animation: ${expandKeyframes({ startingWidth, finalWidth })} ${duration} ${type} forwards;
  -webkit-animation: ${expandKeyframes({ startingWidth, finalWidth })} ${duration} ${type} forwards;
  -moz-animation: ${expandKeyframes({ startingWidth, finalWidth })} ${duration} ${type} forwards;
  -o-animation: ${expandKeyframes({ startingWidth, finalWidth })} ${duration} ${type} forwards;
`;

export const blinkingKeyframes = () => keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const blinkingAnimation = (duration = '1s', type = 'linear') => css`
  animation: ${blinkingKeyframes} ${duration} ${type} infinite;
  -webkit-animation: ${blinkingKeyframes} ${duration} ${type} infinite;
  -moz-animation: ${blinkingKeyframes} ${duration} ${type} infinite;
  -o-animation: ${blinkingKeyframes} ${duration} ${type} infinite;
`;

export const typingKeyframes = () => keyframes`
  from { width: 0 }
  to { width: 100% }
`;

export const typingAnimation = (duration = '1s', type = 'linear') => css`
  animation: ${typingKeyframes} ${duration} steps(44) 1s 1 normal both;
  -webkit-animation: ${typingKeyframes} ${duration} steps(44) 1s 1 normal both;
  -moz-animation: ${typingKeyframes} ${duration} steps(44) 1s 1 normal both;
  -o-animation: ${typingKeyframes} ${duration} steps(44) 1s 1 normal both;
`;

export const shimmerKeyframes = keyframes`
0% {
  background-position: -200px 0;
}
100% {
  background-position: 1000px 0;
}
`;

export const shimmerAnimation = (duration = '7s', type = 'infinite') => css`
  animation: ${shimmerKeyframes} ${duration} ${type} linear;
  -webkit-animation: ${shimmerKeyframes} ${duration} ${type} linear;
  -moz-animation: ${shimmerKeyframes} ${duration} ${type} linear;
  -o-animation: ${shimmerKeyframes} ${duration} ${type} linear;
`;
